<template>
  <div class="container welcome text-center mt-5">
    <h1
      v-if="settings.title"
      :class="settings.title.class"
      :style="settings.title.style"
    >
      {{ settings.title.text }}
    </h1>
    <h5
      v-if="settings.subtitle"
      class="mt-4 mb-5 egx-partner-color"
      :class="settings.subtitle.class"
      :style="settings.subtitle.style"
    >
      {{ settings.subtitle.text }}
    </h5>

    <div v-for="(section, index) in settings.sub_sections" :key="index">
      <div class="mt-5">
        <div
          :class="section.class"
          :style="section.style"
        >
        {{ section.text }}
      </div>
      </div>
    </div>

    <div class="btn-lg row justify-content-center">
      <router-link
        tag="b-button"
        class="btn mt-1 mt-md-3 ml-2 mr-2 btn-egx-white col-12 col-md-3"
        :class="settings.cta.class"
        :style="settings.cta.style"
        :to="settings.cta.path"
      >
        {{ settings.cta.text }}
      </router-link>
    </div>

  </div>
</template>

<script>
export default {
  computed: {
    settings() {
      return this.$store.state.partners.currentPartner.settings.sections.welcome;
    },
  },
};
</script>
