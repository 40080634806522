// eslint-disable-next-line import/no-cycle
import ajax from '../../ajax';

export default {
  namespaced: true,

  state: {
    currentPartner: {
      id: '',
      code: '',
      name: '',
      logo_url: '',
      contact_email: '',
      contact_phone: '',
      website_url: '',
      contact_name: '',
      app_path: '',
      currency_code: '',
      currency_icon: '',
      logo_icon_url: '',
      base_url: '',
      helphero_app_id: '',

      settings: {
        user_consent_mandatory: '',
        terms_of_use_link: '',
        privacy_policy_link: '',
        terms_and_condition_link: '',
        admin_url: '',
        manual_energy_advisor_assignment: '',

        sections: {
          homepage: {
          },
          sign_up: {
          },
          sign_in: {
          },
          home_analysis_page: {
          },
          reset_password_page: {
          },
          create_password_page: {
          },
          update_password_page: {
          },
          not_found: {
          },
        },
      },

      tags: {
        incentive_categories: {
        },
        recommended_categories: {
          all_filters: {
          },
        },
      },
    },
  },

  mutations: {
    set(state, data) {
      state.currentPartner = data;
    },
  },

  actions: {
    async get({ commit }) {
      await ajax.get('/api/v1/partners')
        .then((response) => {
          if (response.data.data === undefined) {
            localStorage.removeItem('vuex');
            window.location.replace(response.data.redirect_url);
          }
          commit('set', response.data.data.attributes);
        })
        .catch((errors) => console.error(errors));
    },
  },
};
