<template>
  <div class="container terms-and-conditions text-center mt-5">
    <h1
      :class="settings.title.class"
      :style="settings.title.style"
    >
      {{ settings.title.text }}
    </h1>
    <div class="content-box col-md-12 mt-5 text-left">
      <div
        v-for="(list, index) in settings.terms_and_conditions_list"
        :key="index"
        class="mt-4 mx-3 mx-md-5"
      >
        <h3
          :class="list.title.class || settings.list_default.title.class"
          :style="list.title.style || settings.list_default.title.style"
          class="pb-3"
        >
          {{ list.title.text }}
        </h3>
        <p
          :class="list.body.class || settings.list_default.body.class"
          :style="list.body.style || settings.list_default.body.style"
          class="pb-3"
          v-html="list.body.text"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    settings() {
      return this.$store.state.partners.currentPartner.settings.sections.terms_and_conditions;
    },
  },
};
</script>
