// eslint-disable-next-line import/no-cycle
import ajax from '../../ajax';

export default {
  namespaced: true,

  state: {
    schedulePlans: {},
  },

  mutations: {
    set(state, data) {
      state.schedulePlans = data;
    },
  },

  actions: {
    get({ commit }) {
      const url = '/api/v1/users_schedule_plans';

      return ajax.get(url).then((response) => {
        commit('set', response.data.data);
      });
    },
  },
};
