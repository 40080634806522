<template>
  <b-container class="loading-screen">
    <div class="content-box constricted">
      <h1
        class="text-center title m-3"
        :class="content.title.class"
        :style="content.title.style"
      >

        {{ content.title.text }}
      </h1>
      <span
        class="d-flex justify-content-center text-center d-flex content"
        :class="content.description.class"
        :style="content.description.style"
      >
        {{ content.description.text }}
      </span>
      <div class="m-4 text-center">
        <font-awesome-icon
          :icon="['fa', 'circle-notch']"
          class="fa-spin m-auto spinner fa-4x"
          :class="content.spinner.class"
          :style="content.spinner.style"
        />
      </div>
      <router-link
        :to="{ name: content.cta.path_name }"
        class="d-flex justify-content-center text-center content mb-3"
        :class="content.cta.class"
        :style="content.cta.style"
      >
        <u>
          {{ content.cta.text }}
        </u>
      </router-link>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'LoadingScreen',

  props: {
    content: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../assets/stylesheets/components/loading-screen";
</style>
