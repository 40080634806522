export default {
  methods: {
    formatZip(zip, countryCode) {
      let zipCode = zip.toUpperCase();
      switch (countryCode.toUpperCase()) {
        case 'CA':
          // Format: XXX XXX
          zipCode = zipCode.replace(/\W/g, '')
            .replace(/(...)/, '$1 ');
          break;
        case 'US':
          // Format: XXXXX
          zipCode = zipCode.replace(/\W/g, '')
            .slice(0, 5);
          break;
        case 'PT':
          // Format: XXXX-XXX
          zipCode = zipCode.replace(/\W/g, '')
            .replace(/(....)/, '$1-');
          break;
        default:
          zipCode = zipCode.slice(0, 10);
      }
      return zipCode;
    },
  },
};
