<template>
  <div class="container building">
    <h1
      class="mt-5 text-center"
      :class="buildingDetails.attributes.title.class"
      :style="buildingDetails.attributes.title.style"
    >
      {{ buildingDetails.attributes.title.text }}
    </h1>
    <h4
      class="text-center"
      :class="buildingDetails.attributes.subtitle.class"
      :style="buildingDetails.attributes.subtitle.style"
    >
      {{ buildingDetails.attributes.subtitle.text }}
    </h4>

    <building-form-fields
      :selectedButton="selectedButton"
      @update-success="redirectToBuildingLists()"
      class="mt-5"
      :key="buildingExist ? currentBuilding.id : null"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '../router/index';
import BuildingFormFields from '../components/BuildingFormFields.vue';

export default {
  name: 'BuildingDetails',

  components: {
    BuildingFormFields,
  },

  created() {
    if (this.buildingExist) {
      this.$store.dispatch('buildings/set', this.$route.params.id).then(() => {
        console.log('Current building updated');
      }).catch((error) => {
        if (error.response) {
          console.log(error.response.data.errors);
          router.push({ name: 'buildings-list' });
        }
      });
    } else {
      this.$store.dispatch('buildings/setNew');
    }
  },

  computed: {
    buildingDetails() {
      return this.$store.state.partners.currentPartner.settings.sections.building_details;
    },

    selectedButton() {
      return this.$route.params.id === undefined ? 'button' : 'button2';
    },

    currentBuilding() {
      return this.$store.state.buildings.currentBuilding;
    },

    buildingExist() {
      return this.$route.params.id !== undefined;
    },
  },

  methods: {
    redirectToBuildingLists() {
      this.$store.dispatch('buildings/setNew').then(() => {
        router.push({ name: 'buildings-list' });
      });
    },
  },
};
</script>
