<template>
  <div v-if="herSettings.enable">
    <b-button
      class="d-flex ml-auto mr-auto mt-3 w-100 justify-content-center btn-single-cta"
      :style="herSettings.style"
      :class="herSettings.class"
      :disabled="!enableButton"
      @click="downloadReport"
    >
      {{ herSettings.text }}
    </b-button>
  </div>
</template>

<script>
import homeEnergyReport from '@/shared/mixins/home-energy-report';

export default {
  name: 'HomeEnergyReportButton',
  props: {
    settings: Object,
    buildingId: [String, Number],
  },
  data() {
    return {
      enableButton: true,
    };
  },
  mixins: [homeEnergyReport],
  computed: {
    herSettings() {
      return this.settings ? this.settings : {};
    },
  },
  methods: {
    downloadReport() {
      this.enableButton = false;
      this.fetchReport(this.buildingId)
        .finally(() => {
          this.enableButton = true;
        });
    },
  },
};
</script>

<style scoped>

</style>
