export default {
  methods: {
    camelToSnake(key) {
      return key.replace(/([A-Z])/g, '_$1').toLowerCase();
    },

    keysToSnakeCase(obj) {
      const newObject = {};
      Object.keys(obj).forEach((camel) => {
        newObject[this.camelToSnake(camel)] = obj[camel];
      });
      return newObject;
    },
  },
};
