<template>
  <div class="container sign-up">
    <form class="sign-up-form">
      <validation-observer>
        <div class="sign-up-form">
          <PageHeader
            :contentHeader="emailVerificationSections"
          />
          
          <PageSectionWithoutForm :pageSection="pageSection" @resend="resend_verification_email"/>
        </div>
      </validation-observer>
    </form>
  </div>
</template>

<script>
import PageHeader from '../../components/dynamicPageComponents/PageHeader.vue';
import PageSectionWithoutForm from '../../components/dynamicPageComponents/PageSectionWithoutForm.vue';

export default {
  name: 'EmailVerification',

  components: {
    PageHeader,
    PageSectionWithoutForm,
  },

  mounted() {
    document.addEventListener('click', this.handleButtonClick);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleButtonClick);
  },

  data() {
    return {
      emailVerificationSettings: {
        content: {},
        header: {},
      },
      emailVerificationPendingSettings: {
        content: {},
        header: {},
      },
    };
  },

  methods: {
    extractSections(section) {
      if(section == 'email_verification') {
        this.emailVerificationSettings.content = this.emailVerificationSections.content.sections.find((item) => {
          return item.type == 'content' && item.title == 'email_verification';
        });
        this.emailVerificationSettings.header = this.emailVerificationSections.content.sections.find((item) => {
        return item.type == 'header' && item.title == 'email_verification';
        });
      } 
      else if (section === 'email_verification_pending') {
        this.emailVerificationPendingSettings.content = this.emailVerificationSections.content.sections.find((item) => {
          return item.type == 'content' && item.title == 'email_verification_pending';
        });
        this.emailVerificationPendingSettings.header = this.emailVerificationSections.content.sections.find((item) => {
        return item.type == 'header' && item.title == 'email_verification_pending';
        });
      }

    },

    replaceKeys(content) {
      let newContent = this.generate_email_link(content);
      newContent = newContent.replace(/{{partner.name}}/g, this.partner.name);
      newContent = newContent.replace(/{{partner.email}}/g, this.partner.contact_email);
      return newContent;
    },

    generate_email_link(content) {
      const url = this.partner.base_url + '/resend-email-verification?token=' + this.$route.query.token;
      const buttonHtml = `<div style="text-align: center;"><button type="button" class="resend-button" style="display: inline-block; padding: 10px 20px; font-size: 16px; color: #fff; background-color: var(--egx-partner-color); text-decoration: none; border-radius: 5px; text-align: center; border: 1px solid var(--egx-partner-color); cursor: pointer;">Resend Verification Email</button></div>`;
      const linkHtml = `<a href="${url}" target="_blank">Resend Verification Email</a>`;
      let newContent = content.replace(/{{user.email_resend_verification_button}}/g, buttonHtml);
      newContent = newContent.replace(/{{user.email_resend_verification_link}}/g, linkHtml);
      return newContent
    },

    handleButtonClick(event) {
      if (event.target.classList.contains('resend-button')) {
        this.resend_verification_email();
      }
    },

    resend_verification_email() {
      this.$router.push({ name: 'ResendEmailVerification', query: { token: this.$route.query.token } });
    },
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },
    emailVerificationSections() {
      return this.partner.settings.sections.email_verification;
    },
    emailVerification(){
      this.extractSections('email_verification');
      if (this.emailVerificationSettings.content.content && this.emailVerificationSettings.content.content.description) {
        const replacedDescription = this.replaceKeys(this.emailVerificationSettings.content.content.description);
        this.emailVerificationSettings.content.content.description = replacedDescription;
      }
      return this.emailVerificationSettings;
    },

    emailVerificationPending(){
      this.extractSections('email_verification_pending');
      if (this.emailVerificationPendingSettings.content.content && this.emailVerificationPendingSettings.content.content.description) {
        const replacedDescription = this.replaceKeys(this.emailVerificationPendingSettings.content.content.description);
        this.emailVerificationPendingSettings.content.content.description = replacedDescription;
      }
      return this.emailVerificationPendingSettings;
    },
    pageSection() {
      return this.$route.query.first_access === 'true' ? this.emailVerification : this.emailVerificationPending;
    }
  },
};
</script>
