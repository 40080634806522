<template>
  <div class="sso-button-section" v-if="settingsSSOButton">
    <hr class="or mt-5 w-50" v-if="position === 'bottom' && !hideSectionDivider" />

    <div
      class="p-4 text-justify text-center"
      :class="settingsSSOButton.description_top.class"
      :style="settingsSSOButton.description_top.style"
      v-html="settingsSSOButton.description_top.text"
      v-if="settingsSSOButton.description_top"
    />
    <a :href="loginUrl" class="text-decoration-none">
      <button
        class="btn btn-lg d-flex ml-auto mr-auto"
        :class="settingsSSOButton.class"
        :style="settingsSSOButton.style"
        type="button"
      >
        {{ settingsSSOButton.text }}
      </button>
    </a>
    <div
      class="p-4 text-justify text-center"
      :class="settingsSSOButton.description_bottom.class"
      :style="settingsSSOButton.description_bottom.style"
      v-html="settingsSSOButton.description_bottom.text"
      v-if="settingsSSOButton.description_bottom"
    />

    <hr class="or my-4 w-75" v-if="position === 'top' && !hideSectionDivider" />
  </div>
</template>

<script>
export default {
  name: 'SSOButtonSection',

  props: {
    position: String,
    loginUrl: String,
    hideSectionDivider: Boolean,
    settingsSSOButton: Object,
  },
};
</script>

<style lang="scss" scoped>
</style>
