<template>
  <div v-if="totalPages > 1">
    <nav aria-label="Page navigation">
      <ul class="pagination flex-wrap">
        <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
          <a class="page-link egx-partner-color" href="#" @click.prevent="prevPage()">Previous</a>
        </li>
        <li class="page-item"
          v-for="page in totalPages"
          :key="page" :class="{ 'active': currentPage === page }"
        >
          <a
            class="page-link egx-partner-color"
            href="#"
            @click.prevent="changePage(page)">{{ page }}
          </a>
        </li>
        <li
          class="page-item"
          :class="{ 'disabled': currentPage === totalPages }"
        >
          <a class="page-link egx-partner-color" href="#" @click.prevent="nextPage()">Next</a>
        </li>
      </ul>
    </nav>
    <slot></slot>
    <nav aria-label="Page navigation">
      <ul class="pagination flex-wrap">
        <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
          <a class="page-link egx-partner-color" href="#" @click.prevent="prevPage()">Previous</a>
        </li>
        <li class="page-item"
          v-for="page in totalPages"
          :key="page" :class="{ 'active': currentPage === page }"
        >
          <a
            class="page-link egx-partner-color"
            href="#"
            @click.prevent="changePage(page)">{{ page }}
          </a>
        </li>
        <li
          class="page-item"
          :class="{ 'disabled': currentPage === totalPages }"
        >
          <a class="page-link egx-partner-color" href="#" @click.prevent="nextPage()">Next</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: this.page + 1,
    };
  },
  props: {
    page: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    totalRecords: {
      type: Number,
      required: true,
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalRecords / this.perPage);
    },
  },

  methods: {
    changePage(page) {
      this.$emit('page-change', (page - 1));
      this.currentPage = page;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.$emit('page-change', (this.currentPage - 1));
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.$emit('page-change', (this.currentPage - 1));
      }
    },
  },
};
</script>

<style lang="scss">
.page-item.active .page-link {
  background-color: var(--egx-partner-color) !important;
  color: white !important;
  border: var(--egx-partner-color);
}

.pagination {
  margin-top: 20px;
}

@media (max-width: 576px) {
  .pagination {
    flex-wrap: wrap;
  }

  .page-item {
    margin-bottom: 5px;
  }
}
</style>
