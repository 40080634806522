<template>
  <reset-password :settings="settings" />
</template>

<script>
import ResetPassword from '../../components/ResetPassword.vue';

export default {
  name: 'CreatePassword',

  components: {
    ResetPassword,
  },

  computed: {
    settings() {
      return this.$store.state.partners.currentPartner.settings.sections
        .create_password_page.create_password;
    },
  },
};
</script>
