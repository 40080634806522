// eslint-disable-next-line import/no-cycle
import ajax from '../../ajax';

export default {
  namespaced: true,

  state: {
    currentVirtualAudit: {
      consumptions: {
        current: {},
        new: {},
      },
      savings: {
        current: {},
        new: {},
      },

    },
  },

  mutations: {
    set(state, data) {
      state.currentVirtualAudit = data;
    },
  },

  actions: {
    post({ commit }, params) {
      const url = '/api/v1/virtual_audit/virtual_audits';
      const payload = {
        virtual_audit: {
          building_id: params,
        },
      };

      return new Promise((resolve, reject) => {
        ajax.post(url, payload)
          .then((response) => {
            const data = response.data.data.attributes;
            commit('set', data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    get({ commit }, params) {
      const url = `/api/v1/virtual_audit/virtual_audits/${params}`;

      return ajax.get(url).then((response) => {
        commit('set', response.data.data.attributes);
      });
    },

    clean({ commit }) {
      commit('set', {});
    },
  },
};
