<template>
  <div class="section-box">
    <validation-observer ref="auditSection">
      <div
        class="d-flex flex-column justify-content-center mt-5 section-box text-center"
        v-if="this.section.main_title"
      >
        <h1 class="egx-partner-color d-flex m-auto text-center justify-content-center">
          {{ this.section.main_title }}
        </h1>
      </div>
      <b-alert show class="text-center text-justify address-banner mt-5">
        <div v-html="subBuildingAttr(this.currentBuilding, this.settings.application_top_alert)" />
      </b-alert>

      <div class="d-flex flex-column justify-content-center mt-5 section-box text-center">
        <h1
          class="d-flex m-auto text-center justify-content-center section-box-title"
        >
          {{ this.section.title }}
        </h1>
        <span class="p-2 d-flex p-3 m-auto text-center section-box-description">
        {{ this.section.description }}
      </span>
      </div>

      <div
        v-for="(subsection, key) in this.subsections"
        :key="subsection.title + '-' + key"
        v-show="!subsection.hide"
      >
        <template>
          <h4 class="form-field-title mb-4 mt-4">{{ subsection.title }}</h4>
          <form-fields
            :fields="subsection.fields"
            :id-prefix="idPrefix"
            :model="sectionsData[key]"
            :sectionTitle="key"
          >
          </form-fields>
        </template>
      </div>

      <div class="row justify-content-center mt-5 no-gutters">
        <button
          type="button"
          class="btn btn-lg d-flex mr-4 btn-egx-grey"
          @click="transitPreviousPage()"
          v-if="prevBtn"
        >
          Previous
        </button>

        <button
          type="button"
          class="btn btn-lg d-flex btn-egx"
          @click="transitNextPage()"
          v-if="nextBtn"
        >
          {{ finalStep ? 'Submit' : 'Next' }}
        </button>
      </div>

      <div v-if="modalSettings">
        <modal-advanced-mode-transition
          :modal-settings="modalSettings"
          :next-page-function="nextPageFunction"
          :selected-generic-items="selectedGenericItems"
        />
      </div>
    </validation-observer>
  </div>
</template>

<script>
import router from '../router';
import SiteNavigationFunctions from '../shared/mixins/SiteNavigationFunctions';
import FormFields from './FormFields.vue';
import ModalAdvancedModeTransition from './modals/ModalAdvancedModeTransition.vue';
import BuildingAddressMixin from '../shared/mixins/building-address';

export default {
  mixins: [
    SiteNavigationFunctions,
    BuildingAddressMixin,
  ],

  props: {
    idPrefix: {
      type: String,
      required: true,
    },

    section: {
      type: Object,
      required: true,
    },

    subsections: {
      type: Object,
      required: true,
    },

    sectionsData: {
      type: Object,
      required: true,
    },

    pageConfiguration: {
      type: Object,
    },
    nextBtn: Boolean,
    prevBtn: Boolean,
    finalStep: Boolean,
  },

  data() {
    return {
      modalSettings: this.getModalSettings(),
      nextPageFunction: this.update,
      addressElements: ['address1', 'address2', 'community'],
    };
  },

  components: {
    FormFields,
    ModalAdvancedModeTransition,
  },

  created() {
    if (
      this.currentBuilding === undefined
      || Object.entries(this.currentBuilding).length === 0
      || this.currentBuilding.id === null
    ) {
      router.push({ name: 'buildings-list' });
    }
  },

  computed: {
    settings() {
      return this.$store.state.partners.currentPartner.settings;
    },

    currentBuilding() {
      return this.$store.state.buildings.currentBuilding;
    },

    selectedGenericItems() {
      return this.$store.getters['generic_items/getGenericData'](this.subsectionList);
    },

    currentGenericItem() {
      return this.$store.state.generic_items.currentGenericItems[this.type];
    },

    subsectionList() {
      return Object.keys(this.subsections);
    },
  },

  methods: {
    async transitPreviousPage() {
      /* eslint-disable camelcase */
      const prevPage = this.section?.configurations?.prev_page;

      await this.$store.dispatch('generic_items/setAllCurrentGenericItems')
        .then(() => {
          this.back(prevPage);
        });
    },

    validateButtons() {
      let validations = 0;

      const keys = Object.keys(this.subsections);

      keys.forEach((key) => {
        const { fields } = this.subsections[key];

        fields.filter((field) => field.required === true).forEach((obj) => {
          const isRequired = obj.required;
          let isPresent = false;

          this.selectedGenericItems.forEach((item) => {
            const selectedItemName = item.data[obj.name];
            if (selectedItemName && selectedItemName.length > 0) {
              isPresent = true;

              if (obj.enable_sub_fields && this.validateChildFields(obj, selectedItemName[0])) {
                validations += 1;
              }
            }
          });

          if (isRequired && !isPresent) {
            validations += 1;
          }
        }, this);
      });

      return validations > 0;
    },

    validateChildFields(obj, selectedItemName) {
      const parent = obj.options.filter((field) => field.name === selectedItemName)[0];

      if (parent?.sub_fields?.required && parent?.sub_fields?.options) {
        const selectedItemObject = this.selectedGenericItems.find(
          (selectedItem) => selectedItem.data[`${selectedItemName}_type`] !== undefined
            || selectedItem.data[parent.sub_fields.name] !== undefined,
        );
        const selectedItemWithType = (
          selectedItemObject.data[`${selectedItemName}_type`]
          || selectedItemObject?.data[parent.sub_fields.name] || []
        );
        let isPresent = selectedItemWithType && selectedItemWithType.length > 0;
        if (isPresent) return !isPresent;

        parent.sub_fields.options.forEach((option) => {
          const selectedItemNameWithType = this.selectedGenericItems[0].data[`${option.name}_type`];
          if (selectedItemNameWithType && selectedItemNameWithType.length > 0) isPresent = true;
        });

        return !isPresent;
      }

      return false;
    },

    notifyValidationsError() {
      this.$notify({
        group: 'notice',
        title: 'Validation Error',
        text: 'Please check all fields have been entered correctly',
      });
    },

    showAdvancedModal() {
      return this.pageConfiguration
        && this.pageConfiguration.virtual_audit
        && this.pageConfiguration.virtual_audit.page_mode === 'lite'
        && this.pageConfiguration.virtual_audit.page_show_change_advanced_mode_modal
        && this.currentBuilding.extra_info
        && this.currentBuilding.extra_info.virtual_audit_mode === 'lite';
    },

    transitNextPage() {
      // validate buttons
      if (this.validateButtons()) {
        this.notifyValidationsError();
        return;
      }

      // validate sliders
      this.$refs.auditSection.validate()
        .then((isValid) => {
          if (!isValid) {
            this.notifyValidationsError();
            return;
          }

          if (this.showAdvancedModal()) {
            this.$bvModal.show('modal-page-transition');
          } else {
            this.update();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    update() {
      const items = this.selectedGenericItems;
      const nextPage = this.section?.configurations?.next_page;

      this.$store.dispatch('generic_items/put', items)
        .then(this.next(nextPage))
        .catch((error) => {
          console.log(`error routing - ${error}`);
        });
    },

    getModalSettings() {
      const modal = this.section.modals
        && this.section.modals.change_advanced_mode;

      if (modal !== undefined) {
        return modal;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/stylesheets/components/section-box.scss';
</style>
