<template>
  <loading-screen v-show="isVisible" :content="loadingScreenContent" />
</template>

<script>
/* eslint-disable import/no-cycle */
import Vue from 'vue';
import router from '../../router/index';
import LoadingScreen from '../../components/LoadingScreen.vue';

export default {
  name: 'LoginAs',

  components: {
    LoadingScreen,
  },

  data() {
    return {
      loadingScreenContent: {
        title: {
          text: 'Loading user',
          class: [],
        },
        description: {
          text: 'Please wait while we load the user profile',
          class: [],
          style: {},
        },
        spinner: {
          class: ['egx-partner-color'],
          style: {},
        },
        cta: {
          text: 'If this page doesn\'t refresh, please click here',
          class: ['egx-partner-color'],
          style: {},
          path_name: 'sign-in',
        },
      },
      isVisible: false,
    };
  },

  beforeRouteEnter(to, from, next) {
    Vue.loginAsToken = to.query.token;

    next();
  },

  mounted() {
    this.beginLoginAs();
  },

  computed: {
    isSSOSignIn() {
      return this.$store.state.users.currentUser.third_party_signup;
    },

    sso() {
      return this.$store.state.partners.currentPartner.sso;
    },

    ssoLandingPage() {
      return this.sso.login_landing_page;
    },
  },

  methods: {
    beginLoginAs() {
      this.isVisible = true;
      const tokenParams = { token: Vue.loginAsToken, admin_id: this.$route.query.admin_id };

      const promise = new Promise((resolve) => {
        resolve(this.$store.dispatch('/api/v1/partners'));
      }).then(() => {
        console.log('Clean auth');
        return this.$store.dispatch('auth/clean');
      }).then(() => {
        console.log('Login with Token');
        return this.$store.dispatch('auth/tokenSignIn', tokenParams)
          .then(() => {
            this.$store.dispatch('auth/adminPermissions')
            .then(()=> {
              this.$store.dispatch('buildings/getAll')
              .then(() => {
                router.push({ name: this.loginLandingPage().toString() });
              });
            })
          });
      });

      Promise.all([promise])
        .catch((error) => {
          console.log(error);
          this.isVisible = false;
          Vue.loginAsToken = '';
        });
    },

    loginLandingPage() {
      if (this.isSSOSignIn && this.sso && this.ssoLandingPage) {
        return this.ssoLandingPage;
      }

      if (this.isSSOSignIn) {
        return 'UserEdit';
      }

      return 'buildings-list';
    },
  },
};
</script>
