<template>
  <div class="maintenance container">
    <span
      class="
        title-maintenance font-roboto-mono d-flex font-weight-lighter m-auto text-center mb-5 p-4
      "
      :class="settings.header.class"
      :style="settings.header.style"
    >
      {{ settings.header.text }}
    </span>
    <h1
      class="text-center mt-5"
      :class="settings.title.class"
      :style="settings.title.style"
    >
      {{ settings.title.text }}
    </h1>
    <h5
      class="text-center mt-3"
      :class="settings.body.class"
      :style="settings.body.style"
    >
      {{ settings.body.text }}
    </h5>
  </div>
</template>

<script>
export default {
  name: 'Maintenance',

  computed: {
    settings() {
      return this.$store.state.partners.currentPartner.settings.sections.maintenance_page;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/stylesheets/components/maintenance.scss';
</style>
