<script>
import { Bar } from 'vue-chartjs';
import numeral from 'numeral';
import chartLabelManager from '@/shared/mixins/chartLabelManager';

export default {
  extends: Bar,
  props: {
    data: Object,
  },
  mixins: [chartLabelManager],

  beforeMount() {
    this.initializeLabels();
  },

  mounted() {
    // Plugin to increase distance between labels and chart
    this.addPlugin({
      id: 'label-raise-height',
      beforeInit: (chart) => {
        // eslint-disable-next-line no-param-reassign
        chart.legend.afterFit = () => {
          this.height += 20;
        };
      },
    });

    this.renderBarChart(this.chartData, this.options);
  },

  data() {
    return {
      chartLabels: {},
    };
  },

  watch: {
    chartLabels() {
      this.renderBarChart(this.chartData, this.options);
    },
  },

  computed: {
    graph() {
      return this.$store.state.partners.currentPartner.settings.sections
        .home_analysis_page.sub_sections.potential_savings.graphic1;
    },

    dataKeys() {
      const currentData = this.data.current;
      return Object.keys(currentData);
    },

    chartData() {
      return {
        labels: this.chartLabels.dataLabels,
        datasets: [
          {
            label: this.chartLabels.bar_label_1,
            backgroundColor: `${this.graph.style.bar_color_1}`,
            data: this.beforeData,
          },
          {
            label: this.chartLabels.bar_label_2,
            backgroundColor: `${this.graph.style.bar_color_2}`,
            data: this.afterData,
          },
        ],
      };
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: this.chartLabels.title,
          position: 'left',
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            formatter: Math.round,
            display: (context) => {
              const chartWidth = context.chart.width;
              if (chartWidth < 480) return false;

              return 'auto';
            },
            font: {
              weight: 'bold',
            },
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              callback(value) {
                return numeral(value)
                  .format('0,0');
              },
            },
          }],
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += ': ';
              }
              label += numeral(tooltipItem.yLabel)
                .format('0,0');
              return label;
            },
          },
        },
      };
    },

    dataLabels() {
      const labels = [];

      this.dataKeys.forEach((keyData) => {
        if (keyData === 'total_consumption_after_retrofit'
          || keyData === 'total_consumption_before_retrofit') {
          return;
        }

        const addedLabel = this.data.current[keyData].label;

        labels.push(addedLabel);
      });

      return labels;
    },

    beforeData() {
      const before = this.data.current;
      const beforeValue = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.dataKeys.length; i++) {
        // eslint-disable-next-line no-continue
        if (this.dataKeys[i] === 'total_consumption_after_retrofit' || this.dataKeys[i] === 'total_consumption_before_retrofit') continue;
        beforeValue.push(before[this.dataKeys[i]].value);
      }
      return beforeValue;
    },

    afterData() {
      const after = this.data.new;
      const afterValue = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.dataKeys.length; i++) {
        // eslint-disable-next-line no-continue
        if (this.dataKeys[i] === 'total_consumption_after_retrofit' || this.dataKeys[i] === 'total_consumption_before_retrofit') continue;
        afterValue.push(after[this.dataKeys[i]].value);
      }
      return afterValue;
    },
  },

  methods: {
    renderBarChart(dataset, options) {
      this.renderChart(dataset, options);
    },

    initializeLabels() {
      const defaultLabels = {
        title: this.graph.y_axis_label || 'Energy consumption in kilowatt-hour (kWh)',
        bar_label_1: 'Before Improvements',
        bar_label_2: 'After Improvements',
        dataLabels: this.dataLabels,
      };

      if (this.graph.labels) {
        defaultLabels.bar_label_1 = this.graph.labels.bar_label_1;
        defaultLabels.bar_label_2 = this.graph.labels.bar_label_2;
      }

      this.chartLabels = defaultLabels;

      this.getLabels(defaultLabels).then((graphLabels) => {
        this.chartLabels = graphLabels;
      });
    },
  },
};
</script>
