<template>
  <div class="container sign-up">
    <form class="sign-up-form" @submit.prevent="signUp()">
      <validation-observer ref="userCreate">
        <div v-show="page1">
          <PageHeader
            :contentHeader="signUpIntegratedFields"
            :setAccountVerification="data.setAccountVerificationValidation"
            :enableSignupIntegrated="enableSignupIntegrated"
            @accountVerification="setAccountVerification"
          />
          <s-s-o-button-section
            position="top"
            :loginUrl="partner.sso.login_url"
            :settingsSSOButton="signUpFields.attributes.sso_button"
            :hideSectionDivider="ssoIssuerLoginOnly"
            v-if="
              partner.sso.allowed &&
              signUpFields.attributes.sso_button &&
              signUpFields.attributes.sso_button.postion_top &&
              !enableSignupIntegrated
            "
          />
          <PageSectionWithForm
            :pageSection="accountVerification"
            :model="data.accountValidationData"
            :messageObj="data.message"
            :novaScotiaAccountExist="data.novaScotiaAccountExist"
            :ssoIssuerLoginOnly="ssoIssuerLoginOnly"
            :idPrefix="idPrefix"
            :sectionType="'account_verification'"
            @findAccount="findAccount"
            @returnTosignup="returnTosignup"
            v-else-if="data.setAccountVerificationValidation"
          />
          <PageSectionWithForm
            :pageSection="accountCreation"
            :model="data.registerUser"
            :messageObj="data.message"
            :novaScotiaAccountExist="data.novaScotiaAccountExist"
            :ssoIssuerLoginOnly="ssoIssuerLoginOnly"
            :idPrefix="idPrefix"
            :sectionType="'account_creation'"
            v-if="(!data.setAccountVerificationValidation || data.message.display) && !data.novaScotiaAccountExist"
          />
        </div>
      </validation-observer>
      <div v-show="!page1">
        <validation-observer
          tag="form"
          @submit.prevent="updateUser()"
          ref="userUpdate"
        >
          <h1
            class="text-center"
            :class="signUpFields.attributes.title2.class"
            :style="signUpFields.attributes.title2.style"
          >
            {{ signUpFields.attributes.title2.text }}
          </h1>
          <h4
            class="text-center"
            :class="signUpFields.attributes.subtitle2.class"
            :style="signUpFields.attributes.subtitle2.style"
          >
            {{ signUpFields.attributes.subtitle2.text }}
          </h4>
          <div class="form-row mt-5">
            <form-field
              :formFields="signUpFields.optionalFields"
              :show="true"
              :model="data.updateUser"
              :id-prefix="idPrefix"
            />
          </div>
          <div class="form-check col-12 pr-0" v-show="signUpFields.consent.show">
            <input
              class="form-check-input" type="checkbox" value=""
              v-model="data.updateUser.consent"
              id="user-consent"
            >
            <label
              class="form-check-label"
              for="user-consent"
              :class="signUpFields.consent.class"
              :style="signUpFields.consent.style"
              v-html="signUpFields.consent.text"
            />
          </div>
          <div class="form-check col-12 pr-0" v-show="signUpFields.marketing.show">
            <input
              class="form-check-input" type="checkbox" value=""
              v-model="data.updateUser.subscribed"
              id="user-subscribed"
            >
            <label
              class="form-check-label"
              for="user-subscribed"
              :class="signUpFields.marketing.class"
              :style="signUpFields.marketing.style"
              v-html="signUpFields.marketing.text"
            />
          </div>
          <button
            type="submit"
            class="btn btn-lg d-flex ml-auto mr-auto mt-5"
            :class="signUpFields.attributes.button2.class"
            :style="signUpFields.attributes.button2.style"
          >
            {{ signUpFields.attributes.button2.text }}
          </button>
        </validation-observer>
      </div>
    </form>
    <notice-section :notice-details="signUpFields.notice_section" />
  </div>
</template>

<script>
import initHelpHero from 'helphero';
import FormField from './form/FormField.vue';
// eslint-disable-next-line import/no-cycle
import SSOButtonSection from './SSOButtonSection.vue';
import NoticeSection from './NoticeSection.vue';
import router from '../router/index';
import FormInputField from '../components/form/InputField.vue';
import PageHeader from './dynamicPageComponents/PageHeader.vue';
import PageSectionWithForm from './dynamicPageComponents/PageSectionWithForm.vue';

export default {
  name: 'SignUpIntegrated',

  components: {
    FormField,
    NoticeSection,
    SSOButtonSection,
    FormInputField,
    PageHeader,
    PageSectionWithForm,
  },

  mounted() {
    this.data.updateUser.consent = this.signUpFields?.consent?.checked;
    this.data.updateUser.subscribed = this.signUpFields?.marketing?.checked;
  },

  data() {
    return {
      page1: true,
      idPrefix: 'sign-up-page',

      data: {
        registerUser: {
          name: 'New user',
          accountId: undefined,
        },

        updateUser: {
          consent: false,
          subscribed: false,
          community: '',
          country: '',
          region: '',
        },
        accountCreation: {
          content: {},
          header: {}
        },
        accountVerification: {
          content: {},
          header: {}
        },
        accountValidationData: {},
        setAccountVerificationValidation: false,
        novaScotiaAccountExist: false,
        message: {
          display: false,
          type: 'success'
        }
      },
    };
  },

  methods: {
    nextPage() {
      this.page1 = !this.page1;
    },

    signUp() {
      if (this.validatePasswordConfirmation()) {
        this.$refs.userCreate.validate()
          .then((isValid) => {
            if (isValid) {
              const emailVerification = this.partner.settings.sections.partner_email_verification.enable_email_verification;
              this.$store.dispatch('users/post', { user_data: this.data.registerUser, email_verification: emailVerification })
                .then((response) => {
                  if(emailVerification) {
                    router.push({ name: 'EmailVerification', query: {token: response.token, first_access: true} });
                  } else {
                    const helpHero = initHelpHero(`${this.partner.helphero_app_id}`);
                    helpHero.identify(this.currentUser.id);
                    if(this.data.message.display && this.data.message.type == 'success') {
                      if (!this.data.registerUser.accountId) {
                        this.updateMockUser();
                      } else {
                        router.push({ name: 'buildings-list' });
                      }
                    }
                    else {
                      this.nextPage();
                    }
                  }
                })
                .catch((error) => {
                  this.$notify({
                    group: 'notice',
                    text: error.response.data.errors[0].title,
                  });
                });
            } else {
              this.$notify({
                group: 'notice',
                text: 'Fill in all required fields.',
              });
            }
          });
      } else {
        this.$notify({
          group: 'notice',
          text: 'password and password confirmation must be the same.',
        });
      }

    },

    updateUser() {
      if (!this.data.updateUser.consent) {
        this.$notify({
          group: 'notice',
          text: 'You must agree to the terms and conditions to proceed.',
        });
      } else {
        this.$refs.userUpdate.validate()
          .then((isValid) => {
            if (isValid) {
              this.$store.dispatch('users/put', this.data.updateUser)
                .then(() => router.push({ name: 'buildings-list' }))
                .catch((error) => {
                  this.$notify({
                    group: 'notice',
                    text: error.response.data.errors[0].title,
                  });
                });
            } else {
              this.$notify({
                group: 'notice',
                text: 'Fill in all required fields.',
              });
            }
          });
      }
    },
    updateMockUser() {
      const userData = {
        consent: true,
        subscribed: true,
        community: "",
        country: "",
        region: "WI",
        first_name: "user",
        last_name: "mock",
        mailing_address: "User Street",
        city: "mock city",
        zip: "12345"
      }
      this.$store.dispatch('users/put', userData)
        .then(() => this.createMockBuilding())
        .catch((error) => {
          this.$notify({
            group: 'notice',
            text: error.response.data.errors[0].title,
          });
        });
    },
    createMockBuilding() {
      const mockBuilding = {
        id: null,
        construction_year: 1995,
        floor_area: null,
        floors_above_ground: null,
        floors_below_ground: null,
        address1: 'mock street',
        address2: null,
        city: 'mock city',
        region: 'WI',
        zip: '12345',
        country: 'US',
        profile_type: null,
        heating_type: null,
        heating_bill_occurrence: 'monthly',
        heating_cost: null,
        heating_usage: null,
        gas_usage_unit: 'm3',
        electric_summer_cost: null,
        electric_summer_usage: null,
        electric_winter_cost: null,
        electric_winter_usage: null,
        electrical_bill_occurrence: 'monthly',
        water_cost: null,
        water_usage: null,
        water_usage_unit: 'gal',
        water_bill_occurrence: null,
        admin_id: null,
        extra_info: {},
        total_project_update_requested: 0,
        ea_assignment: {},
        has_contacted_energy_advisor: false,
        rebate_project_type_ids: [],
        available_energy_advisors_with_admins: [],
        building_name: 'Main residence',
        ownership: 'own',
        electric_utility: '',
        gas_utility: ''
      }
      this.$store.dispatch('buildings/update', mockBuilding)
        .then(() => {
          console.log("creating building");
          router.push({ name: 'buildings-list' });
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.errors);

            this.$notify({
              group: 'notice',
              text: error.response.data.errors[0].title,
            });
          }
        });
    },

    validatePasswordConfirmation() {
      if (this.data.registerUser.password !== this.data.registerUser.password_confirmation) {
        return false;
      }
      return true;
    },
    extractSections(section) {
      if(section == 'verification') {
        this.data.accountVerification.content = this.signUpIntegratedFields.content.sections.find((item) => {
          return item.type == 'content' && item.title == 'account_verification'
        });
        this.data.accountVerification.header = this.signUpIntegratedFields.content.sections.find((item) => {
        return item.type == 'header' && item.title == 'account_verification'
        })
      } else if (section === 'creation') {
        this.data.accountCreation.content = this.signUpIntegratedFields.content.sections.find((item) => {
          return item.type == 'content' && item.title == 'account_creation'
        });
        this.data.accountCreation.header = this.signUpIntegratedFields.content.sections.find((item) => {
        return item.type == 'header' && item.title == 'account_creation'
        })
      }

    },

    setAccountVerification() {
      this.data.setAccountVerificationValidation = !this.data.setAccountVerificationValidation
    },

    returnTosignup(path) {
      this.data.novaScotiaAccountExist = false;
      this.setAccountVerification();
      return path;
    },

    findAccount() {
      if(this.data.accountValidationData.building_address && this.data.accountValidationData.partner_user_id) {
        this.$store.dispatch(
          'users/accountVerification',
          {
            address: this.data.accountValidationData?.building_address,
            accountId: this.data.accountValidationData?.partner_user_id,
          }
        )
          .then((response) => {
            let type = null;
            this.data.registerUser.accountId = null;
            this.data.registerUser.email = null;

            if (response.success && !response.userExist) {
              type = 'success';
              this.data.registerUser.accountId = response.accountId;
              this.data.registerUser.email = response.email;
            } else if (response.success && response.userExist) {
              type = 'userExist';
              this.data.novaScotiaAccountExist = true;
            }
            else {
              type = 'warning';
            }

            this.data.message = {
              display: true,
              type: type
            }
          })
          .catch((error) => {
            this.$notify({
              group: 'notice',
              text: error.response.data.errors[0].title,
            });
          });
      } else {
        this.$notify({
          group: 'notice',
          text: 'Utility account number and Civic street address are required.',
        });
      }
    }
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    signUpFields() {
      return this.partner.settings.sections.sign_up;
    },
    signUpIntegratedFields() {
      return this.partner.settings.sections.signup_integrated;
    },

    currentUser() {
      return this.$store.state.users.currentUser;
    },

    ssoBtnAllow() {
      return this.partner.sso.allowed && this.partner.sso.login_url;
    },

    ssoIssuerLoginOnly() {
      return this.partner.sso.allowed && this.partner.sso.issuer_login_only;
    },
    accountCreation() {
      this.extractSections('creation')
      return this.data.accountCreation;
    },
    accountVerification() {
      this.extractSections('verification')
      return this.data.accountVerification;
    },
    enableSignupIntegrated() {
      return this.partner.settings.enable_sign_up_integrated;
    },
  },
};
</script>
