<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Dialog from './Dialog.vue';

export default {
  components: {
    FullCalendar,
    Dialog,
  },
  props: {
    propsEvents: null,
  },
  data() {
    return {
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: '',
        },
        initialView: 'timeGridWeek',
        editable: false,
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        longPressDelay: 100,
        eventLongPressDelay: 10000,
        select: (event) => this.handleDateSelect(event),
        eventClick: (event) => this.handleEventClick(event),
      },
      dialogVisible: false,
      events: [],
      clickedEvent: null,
      changedEvent: {
        status: '',
        title: '',
        start: '',
        end: '',
        allDay: false,
        borderColor: '',
        description: '',
        energyAdvisorName: '',
        tag: {
          color: '#FFB6B6',
        },
      },
      view: false,

    };
  },

  mounted() {
    this.events = [...this.propsEvents];
    this.calendarOptions = { ...this.calendarOptions, events: this.events };
  },
  watch: {
    propsEvents(newProps) {
      this.events = [...newProps];
      this.calendarOptions = { ...this.calendarOptions, events: this.events };
    },
  },
  methods: {
    handleDateSelect() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    handleEventClick(event) {
      this.clickedEvent = event.event;
      const plainEvent = event.event.toPlainObject(
        {
          collapseExtendedProps: true,
          collapseColor: true,
        },
      );
      this.view = 'display';
      this.dialogVisible = true;
      this.changedEvent = { ...plainEvent, ...this.clickedEvent };
      this.changedEvent.start = this.clickedEvent.start;
      this.changedEvent.end = this.clickedEvent.end
        ? this.clickedEvent.end
        : this.clickedEvent.start;
    },
    handleSchedule() {
      this.view = 'confirmation';
    },
    handleSave() {
      this.$emit('submit', {
        schedulePlanTimeslotId: this.changedEvent.id,
        schedulePlanTimeslot: this.changedEvent,
      });
      this.dialogVisible = false;
    },
  },

  computed: {
    currentBuilding() {
      const building = this.$store.state.buildings.currentBuilding;
      return `
        ${building.address1 ? `${building.address1},` : ''}
        ${building.address2 ? `${building.address2},` : ''}
        ${building.city ? `${building.city},` : ''}
        ${building.region ? `${building.region},` : ''}
        ${building.country || ''}
      `;
    },
    currentUser() {
      return this.$store.state.users.currentUser;
    },
  },
};
</script>

<template>
  <div class="fc">
    <FullCalendar :options="calendarOptions" :events="events" />

    <Dialog :show="dialogVisible" @close="$event => closeDialog()">
      <div v-if="view === 'display'">
        <div class="col">
          <div class="text-900 font-weight-bold mb-1">Application Details</div>
          <div class="mb-2">{{ changedEvent.project_name }}</div>
          <div class="text-900 font-weight-bold mb-1">Energy Advisor</div>
          <div class="mb-2">{{ changedEvent.energyAdvisorName }}</div>
          <div class="text-900 font-weight-bold mb-1">User</div>
          <div class="mb-2">{{ currentUser.name }}</div>
          <div class="text-900 font-weight-bold mb-1">Building</div>
          <div class="mb-2">{{ currentBuilding }}</div>
          <div class="text-900 font-weight-bold mb-1">Start at</div>
          <p class="d-flex align-items-center mb-2">
            <i class="text-muted"></i>
            <span>{{ changedEvent.start.toString().slice(0, 21) }}</span>
          </p>
          <div class="text-900 font-weight-bold mb-1">Finish at</div>
          <p class="d-flex align-items-center mb-2">
            <i class="text-muted"></i>
            <span>{{ changedEvent.end.toString().slice(0, 21) }}</span>
          </p>
        </div>
        <div class="d-flex justify-content-end mt-3 w-100">
          <div>
            <button
              class="btn btn-egx-white text-black"
              @click="$event => closeDialog()">Cancel</button>
          </div>
          <div class="ml-4">
            <button class="btn btn-egx" type="button" @click="$event => handleSave()">
              Schedule
            </button>
          </div>
        </div>
      </div>

      <div v-else>
        <div>
          <div class="col mb-5 ">
            <span>Confirm schedule Assignment?</span>
          </div>

          <div class="row justify-content-center">
            <div class="col-md-3">
              <button class="btn btn-success" @click="$event => handleSave()">Confirm</button>
            </div>
            <div class="col-md-3">
              <button class="btn btn-danger" @click="$event => closeDialog()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<style lang='scss' >
.fc {
  width: 100%;
}
.fc-button-primary {
  background-color: var(--egx-partner-color) !important;
}

@media (max-width: 500px) {
  .fc-header-toolbar {
    display: flex;
    flex-direction: column;

    .fc-button {
      line-height: 1;
      min-height: 2.07rem;
    }
  }
}
</style>
