export default {
  namespaced: true,

  state: {
    currentAdmin: {},
  },

  mutations: {
    set(state, data) {
      state.currentAdmin = data;
    },

    setExtraInfo(state, data) {
      state.currentAdmin.extra_info = data;
    },
    setPermission(state, data) {
      state.currentAdmin.permissions = data;
    }
  },

  actions: {
    setAdminId({ commit }, adminId) {
      commit('set', { id: adminId });
    },

    clean({ commit }) {
      commit('set', {});
    },
  },
};
