<template>
  <div
    class="justify-content-center text-center"
    :class="this.section.class"
    :style="this.section.style"
  >
    <h1
      class="pt-4 text-center egx-partner-color"
      :style="this.section.title.style"
      :class="this.section.title.class"
      v-if="!!this.section.title"
    >
      {{ this.section.title.content }}
    </h1>
    <h5
      class="p-4 mb-4 justify-content-center"
      :style="this.section.description.style"
      :class="this.section.description.class"
      v-if="!!this.section.description"
    >
      {{ this.section.description.content }}
    </h5>
    <iframe
      class="mb-1"
      :src="this.section.iframe.src"
      :style="this.section.iframe.style"
      :class="this.section.iframe.class"
      scrolling="no"
    />
  </div>
</template>

<script>
export default {
  props: {
    section: Object,
  },
};
</script>

<style lang="scss" scoped>
</style>
