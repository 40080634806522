export default {
  computed: {
    nodeEnv() {
      return process.env.NODE_ENV;
    },

    environment() {
      if (this.nodeEnv === 'development') return 'development';

      const urlSeparated = window.location.hostname.split('.');
      if (urlSeparated.includes('qa')) return 'qa';

      if (urlSeparated.includes('staging')) return 'staging';

      if (urlSeparated.includes('myenergyxpert')) return 'production';

      console.warn('Unknown environment');
      return 'unknown';
    },
  },
};
