/* eslint-disable import/no-cycle, no-param-reassign */
import store from './store/index';
import router from './router/index';

const axios = require('axios');
const qs = require('qs');

const baseUrl = () => {
  const subdomain = window.location.hostname.split('.')[0];
  const { protocol } = window.location;
  const { hostname } = window.location;

  return `${protocol}//${hostname.replace(subdomain, `${subdomain}`)}${process.env.VUE_APP_PORT}`;
};

function sessionExpireRedirectPath() {
  return store.state.partners.currentPartner.sso.session_expire_redirect_path;
}

axios.defaults.withCredentials = true;

const ajax = axios.create({
  baseURL: baseUrl(),
  headers: {
    Authorization: 'Token 7DE21FED165ABF4E589B4715D985E',
    'Content-Type': 'application/json',
  },
});

ajax.interceptors.request.use((config) => {
  // eslint-disable
  if (!config.url.includes('auth') && (config.method === 'post' || config.method === 'put' || config.method === 'delete')) {
    config.headers = { ...config.headers, 'X-CSRF-Token': store.state.auth.CSRF };
  }

  config.headers = { ...config.headers, 'X-ENERGYX-ServiceRequester': 'mexv' };

  // This configuration is needed to prevent Axios from converting all
  // nested objects values into a string
  config.paramsSerializer = (params) => qs.stringify(params, {
    arrayFormat: 'brackets',
    encode: 'false',
  });

  return config;
});

ajax.interceptors.response.use((response) => response, (error) => {
  if (error.request !== undefined) {
    if (!error.request.responseURL.includes('auth') && error.request.status === 401) {
      if (!error.config.url.includes('auth') && error.request.status === 401) {
        console.info('SESSION EXPIRED, REDIRECT TO LOGIN');
        store.dispatch('auth/signOut');
        let redirectPath = sessionExpireRedirectPath() || 'sign-in';

        let query = { redirect: '' };
        const redirectURI = encodeURI(window.location.pathname + window.location.search);
        if (window.location.pathname.includes('sign-in')) {
          redirectPath = 'sign-in';
          query = {};
        } else {
          query.redirect = redirectURI;
        }

        query = sessionExpireRedirectPath() ? {} : query;

        router.push({
          name: redirectPath,
          query,
        });

        return Promise.reject(error);
      }
    }
  }
  return Promise.reject(error);
});

export default ajax;
