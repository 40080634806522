<template>
  <div>
    <thank-you />
  </div>
</template>

<script>
import ThankYou from '../components/ThankYou.vue';

export default {
  components: {
    ThankYou,
  },
};
</script>
