import Vue from 'vue';
// eslint-disable-next-line import/no-cycle
import ajax from '../../ajax';

export default {
  namespaced: true,

  offers: {
    allOffers: [],
    currentOffers: [],
    allOfferTags: [],
    softRecommendedOffers: [],
    appliedOfferTags: [],
    isLoading: false,
  },

  mutations: {
    setAll(state, data) {
      state.allOffers = data;
    },

    set(state, data) {
      state.currentOffers = data;
    },

    setItem(state, data) {
      const index = state.allOffers.findIndex((item) => item.id === data.id);

      if (index !== -1) {
        state.allOffers[index] = data;
      }
    },

    setSoftRecOffers(state, data) {
      state.softRecommendedOffers = data;
    },

    setAllTags(state, data) {
      Vue.set(state, 'allOfferTags', data);
    },

    setAppliedTags(state, data) {
      Vue.set(state, 'appliedOfferTags', data);
    },
  },

  actions: {
    async getAll({ commit, dispatch, state }) {
      state.isLoading = true;
      const params = { building_id: this.state.buildings?.currentBuilding?.id };

      await ajax.get('/api/v2/offers', { params })
        .then((response) => {
          commit('setAll', response.data.data);
          dispatch('setSoftRecommendedOffers');
          dispatch('setAllOffersTags', []);
          dispatch('setAppliedOfferTags', []);
          state.isLoading = false;
        })
        .catch((errors) => {
          console.error(errors);
          state.isLoading = false;
        });
    },

    get({ commit }, params) {
      const offerId = params.id;

      return ajax.get(`/api/v2/offers/${offerId}`)
        .then((response) => {
          commit('setItem', response.data.data);
          return response.data.data;
        })
        .catch((errors) => {
          console.error(errors);
        });
    },

    setAllOffersTags({ commit }, data) {
      commit('setAllTags', data);
    },

    setAppliedOfferTags({ commit }, data) {
      commit('setAppliedTags', data);
    },

    setSoftRecommendedOffers({ commit, getters }) {
      const offers = this.state.offers.allOffers
        .filter((obj) => this.state.partners.currentPartner.soft_recommended_offers
          .includes(obj.attributes.id) && getters.checkWithinDateRange(obj));

      commit('setSoftRecOffers', offers);
    },

    clean({ commit }) {
      commit('setAll', []);
      commit('set', {});
      commit('setAllTags', []);
      commit('setAppliedTags', []);
      commit('setSoftRecOffers', []);
    },
  },

  getters: {
    checkAvailableQuantity: (state, getters) => (offer) => {
      const projectTypeId = offer.attributes.project_type?.id;

      if (getters.checkObjIsEmpty(projectTypeId)) return true;

      // When there is conflict between user and building, user setting take priority
      // return true for available, false for not available

      return getters.checkUserAvailableQuantity(offer, projectTypeId)
        && getters.checkBuildingAvailableQuantity(offer, projectTypeId);
    },

    checkUserAvailableQuantity: (state, getters, rootState) => (offer, projectTypeId) => {
      const userActive = offer.attributes.user_quantity_limitation?.active;

      if (userActive) {
        // Need to check projects belongs to all buildings, NOT just current building
        const projects = rootState.buildings.allBuilding
          .map((building) => building.attributes.rebate_project_type_ids
            .filter((selectedProjectTypeId) => selectedProjectTypeId === projectTypeId));

        let projectsArray = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < projects.length; i++) {
          projectsArray = projectsArray.concat(projects[i]);
        }

        const userQuantityMax = offer.attributes.user_quantity_limitation.max;
        const userStartDate = getters.parseDate(
          offer.attributes.user_quantity_limitation.fiscal_year.start_at,
        );
        const userEndDate = getters.parseDate(
          offer.attributes.user_quantity_limitation.fiscal_year.end_at,
        );

        // Check all projects created in the fiscal year date range
        projectsArray = projectsArray.filter((project) => {
          // When start and end date are empty, consider project created within the date range
          if (getters.checkObjIsEmpty(userStartDate)
            || getters.checkObjIsEmpty(userEndDate)) return true;

          const projectCreatedAt = new Date(project.created_at);
          return projectCreatedAt > userStartDate && projectCreatedAt < userEndDate;
        });

        // if no projects in the date range, depends on if the max quantity > 0
        if (projectsArray.length === 0) {
          return userQuantityMax > 0;
        }

        // Check if project involve with object quantity
        // list item key: 'incentive_calculator_user_input_quantity_1'
        const hasObjQuantity = projectsArray[0].applied_obj_quantity;
        if (hasObjQuantity === undefined || hasObjQuantity === null) {
          // If project does not have applied_obj_quantity
          // compare number of projects with the quantity max
          return projectsArray.length < userQuantityMax;
        }
        // compare with sum of 'incentive_calculator_user_input_quantity_1' list item
        const objQuantitySum = projectsArray
          .reduce((accumulator, project) => accumulator + project.applied_obj_quantity, 0);
        return objQuantitySum < userQuantityMax;
      }

      return true;
    },

    checkBuildingAvailableQuantity: (state, getters, rootState) => (offer, projectTypeId) => {
      const buildingActive = offer.attributes.building_quantity_limitation?.active;

      if (buildingActive) {
        const buildingQuantityMax = offer.attributes.building_quantity_limitation.max;
        const buildingStartDate = getters.parseDate(
          offer.attributes.building_quantity_limitation.fiscal_year.start_at,
        );
        const buildingEndDate = getters.parseDate(
          offer.attributes.building_quantity_limitation.fiscal_year.end_at,
        );

        const rebateProjects = rootState.buildings.currentBuilding.rebate_project;
        const projects = rebateProjects
          .filter((project) => project.project_type.id === projectTypeId);

        // Check all projects created in the fiscal year date range
        const filteredProject = projects.filter((project) => {
          // When start and end date are empty, consider then as created within the date range
          if (getters.checkObjIsEmpty(buildingStartDate)
            || getters.checkObjIsEmpty(buildingEndDate)) return true;

          const projectCreatedAt = new Date(project.created_at);
          return projectCreatedAt > buildingStartDate && projectCreatedAt < buildingEndDate;
        });

        // if no projects in the date range, depends on if the max quantity > 0
        if (filteredProject.length === 0) {
          return buildingQuantityMax > 0;
        }

        // Check if project involve with object quantity
        // list item key: 'incentive_calculator_user_input_quantity_1'
        const hasObjQuantity = filteredProject[0].applied_obj_quantity;
        if (hasObjQuantity === undefined || hasObjQuantity === null) {
          // If project does not have applied_obj_quantity
          // compare number of projects with the quantity max
          return filteredProject.length < buildingQuantityMax;
        }
        // compare with sum of 'incentive_calculator_user_input_quantity_1' list item
        const objQuantitySum = filteredProject
          .reduce((accumulator, project) => accumulator + project.applied_obj_quantity, 0);

        return objQuantitySum < buildingQuantityMax;
      }

      return true;
    },

    checkObjIsEmpty: () => (object) => object === undefined || object === null || object === '',
    // eslint-disable-next-line no-restricted-globals
    parseDate: () => (dateString) => (isNaN(Date.parse(dateString)) ? '' : new Date(dateString)),

    // return true when current date is within both user and building quantity limitation
    // The reason why it's checking again here and not using the one in the back end:
    // the back end is checking for the ai recommended offers which show in HomeOverview Page
    // the method here is for the offers that show on the incentive page which comes from allOffer

    checkWithinDateRange: (state, getters) => (offer) => getters
      .checkDateRange(offer.attributes.user_quantity_limitation)
      && getters.checkDateRange(offer.attributes.building_quantity_limitation),

    checkDateRange: (_, getters) => (limitation) => {
      // return true if current offer doesn't have quantity limitation
      if (getters.checkObjIsEmpty(limitation)) return true;

      // return true if the limitation is not active
      if (limitation.active === false) return true;

      // return true if current limitation doesn't have fiscal year
      const fiscalYear = limitation.fiscal_year;
      if (getters.checkObjIsEmpty(fiscalYear)) return true;

      const startAt = fiscalYear.start_at;
      const endAt = fiscalYear.end_at;

      // return true if either start at or end at is empty string
      if (getters.checkObjIsEmpty(startAt) || getters.checkObjIsEmpty(endAt)) return true;

      const currentDate = new Date();
      const start = new Date(startAt);
      const end = new Date(endAt);

      return currentDate > start && currentDate < end;
    },
  },
};
