import Vue from 'vue';
/* eslint-disable import/no-cycle, no-return-assign,
 array-callback-return, consistent-return, no-empty-pattern */
import ajax from '../../ajax';

export default {
  namespaced: true,

  state: {
    genericItems: {},
    currentGenericItems: {},
    genericItemsSectionData: {},
  },

  mutations: {
    setAll(state, data) {
      state.currentGenericItems = data;
    },

    set(state, response) {
      Vue.set(state.currentGenericItems, response.item_type, response.data[0]);
    },

    setSectionData(state, item) {
      Vue.set(state.currentGenericItems, item.key, item.value);
    },

    setSectionValues(state, item) {
      state.genericItemsSectionData[item.key].data.types = item.value;
    },

    clearSectionsData(state) {
      state.genericItemsSectionData = {};
    },

    create(state, response) {
      Vue.set(state.currentGenericItems, response.item_type, response.data);
    },

    delete(state, response) {
      const items = state.genericItems[response.sectionType];
      state.genericItems[response.sectionType] = items.filter((item) => item.id !== response.id);
    },
  },

  actions: {
    setAllCurrentGenericItems({ commit }) {
      return ajax.get('/api/v1/generic_items')
        .then((response) => {
          response.data.data.forEach((item) => {
            commit('create', { item_type: item.attributes.item_type, data: item.attributes });
          });
        })
        .catch((error) => Promise.reject(error));
    },

    updateSectionData({ commit }, data) {
      commit('setSectionData', data);
    },

    setGenericItemsSectionData({ dispatch }, { sections, buildingId }) {
      const self = this;
      sections.forEach((section) => {
        const subSections = Object.entries(Object.values(section)[0].sub_sections);

        subSections.forEach(([key, value]) => {
          if (self.state.generic_items.currentGenericItems[key] === undefined) {
            const data = {};

            value.fields.map((field) => data[field.name] = []);

            const params = {
              itemType: key,
              buildingId,
              data,
            };

            dispatch('setNew', params);
          }
        });
      });
    },

    setNew({ commit }, params) {
      return ajax.get('/api/v1/generic_items/new', {
        params: {
          generic_item: {
            building_id: params.buildingId,
            item_type: params.itemType,
            data: JSON.stringify(params.data),
            genericable_id: params.buildingId,
            genericable_type: 'Building',
          },
        },
      })
        .then((response) => {
          commit('setSectionData', { value: response.data.data.attributes, key: response.data.data.attributes.item_type });
        })
        .catch((error) => Promise.reject(error));
    },

    get({ commit }, itemType) {
      return ajax.get('/api/v1/generic_items', {
        params: {
          type: itemType,
        },
      })
        .then((response) => commit('set', { data: response.data.data, item_type: itemType }))
        .catch((error) => Promise.reject(error));
    },

    post({ commit }, params) {
      const payload = {
        generic_item: {
          building_id: params.buildingId,
          item_type: params.sectionType,
          data: params.data,
          genericable_id: params.buildingId,
          genericable_type: 'Building',
        },
      };

      return ajax.post('/api/v1/generic_items', payload)
        .then((response) => commit('create', { data: response.data.data, item_type: params.sectionType }));
    },

    put({ commit }, params) {
      const url = '/api/v1/generic_items/0';

      const payload = {
        generic_item: {
          items_list: params,
        },
        enableOnlyOneGenericItem: true,
      };

      return ajax.put(url, payload)
        .then((response) => {
          response.data.data.forEach((item) => {
            commit('create', { item_type: item.attributes.item_type, data: item.attributes });
          });
        })
        .catch((error) => Promise.reject(error));
    },

    delete({ commit }, params) {
      const url = `/api/v1/generic_items/${params.id}`;

      return ajax.delete(url)
        .then(() => commit('delete', params));
    },

    clean({ commit }) {
      commit('setAll', {});
    },
  },

  getters: {
    getGenericData: (state) => (sectionData) => {
      const items = Object.keys(state.currentGenericItems)
        .map((key) => {
          if (sectionData.includes(key)) {
            return state.currentGenericItems[key];
          }
        }).filter((element) => element !== undefined);

      return items;
    },
  },
};
