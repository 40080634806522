import LocalizeMixin from './localize';

export default {
  // Created so labels can be translated before being used in graph
  // Labels get translated when `this.enableLocalize` is `true`

  methods: {
    getLabels(initialLabels) {
      return new Promise((resolve) => {
        if (!this.enableLocalize) {
          // Return defaults if not using Localize
          resolve(initialLabels);
        } else {
          // Build labels as an HTML string for Localize
          const htmlLabels = this.buildHtmlLabelsToString(initialLabels);
          // Translate labels and rebuild them as json again
          // eslint-disable-next-line no-undef
          Localize.translate(htmlLabels, {}, (localizedHtml) => {
            // Re-format translated HTML string back into JSON
            const newLabels = this.parseLocalizedLabelsToJson(localizedHtml, initialLabels);
            resolve(newLabels);
          });
        }
      });
    },

    buildHtmlLabelsToString(labelJson) {
      // Build <span> html tags from label json so Localize can translate
      // e.g { titleLabel: 'My Title' } => <span isolate id="titleLabel-label-lc">My Title</span>
      let resultHtml = '';
      const keys = Object.keys(labelJson);
      keys.forEach((key) => {
        if (typeof labelJson[key] === 'string') {
          resultHtml += `<span isolate id='${key}-label-lc'>${labelJson[key]}</span>`;
        } else if (typeof labelJson[key] === 'object' && Array.isArray(labelJson[key])) {
          // Parse each item of array as a different <span> with id: '(parent_key)-(index)-label-lc'
          labelJson[key].forEach((labelItem, index) => {
            resultHtml += `<span isolate id='${key}-${index}-label-lc'>${labelJson[key][index]}</span>`;
          });
        } else {
          console.log(`Unknown object type ${typeof labelJson[key]}`);
        }
      });
      return resultHtml;
    },

    parseLocalizedLabelsToJson(localizedHtml, graphLabels) {
      // Parse translated html tags back into default json
      // e.g <span isolate id="titleLabel-label-lc">Mon Titre</span> => { titleLabel: 'Mon Titre' }
      const newLabels = {};
      const parser = new DOMParser();
      // Parse translated HTML string as text/html
      const parsedHtml = parser.parseFromString(localizedHtml, 'text/html');
      Object.keys(graphLabels).forEach((key) => {
        if (typeof graphLabels[key] === 'string') {
          // Parse string by grabbing text from <span>
          const translatedText = parsedHtml.querySelector(`#${key}-label-lc`);
          if (translatedText !== null) newLabels[key] = translatedText.textContent;
        } else if (typeof graphLabels[key] === 'object' && Array.isArray(graphLabels[key])) {
          // Parse array by grabbing text from each span belonging to the key
          // and adding text to array
          newLabels[key] = [];
          graphLabels[key].forEach((labelItem, index) => {
            const translatedText = parsedHtml.querySelector(`#${key}-${index}-label-lc`);
            if (translatedText.length !== null) newLabels[key].push(translatedText.textContent);
          });
        } else {
          console.log(`Unknown object type ${typeof graphLabels[key]}`);
        }
      });
      return newLabels;
    },
  },
  mixins: [LocalizeMixin],
};
