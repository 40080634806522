<template>
  <div class="row mt-3 mb-3 form-field m-auto">
    <div
      v-for="field in this.fields"
      :key="field.title"
      class="form-group"
      v-show="!field.hide"
    >
      <button-options
        class="col-12"
        :field="field"
        :multi-select="false"
        :sectionTitle="sectionTitle"
        :isSubField="false"
        v-if="field.type === 'button-options'"
      />

      <range-slider
        class="col-12"
        :field="field"
        :sectionTitle="sectionTitle"
        v-if="field.type === 'range-slider'"
      />

      <range-input
        class="col-12"
        :field="field"
        :sectionTitle="sectionTitle"
        v-if="field.type === 'range-input'"
      />
    </div>
  </div>
</template>

<script>
import ButtonOptions from './ButtonOptions.vue';
import RangeSlider from './RangeSlider.vue';
import RangeInput from './RangeInput.vue';

export default {

  components: {
    ButtonOptions,
    RangeSlider,
    RangeInput,
  },

  props: {
    idPrefix: {
      type: String,
      required: true,
    },

    fields: {
      type: Array,
      required: true,
    },

    model: {
      type: Object,
      required: false,
    },

    sectionTitle: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../assets/stylesheets/components/form-fields.scss";
</style>
