<template>
  <div
    v-if="showAlert"
    id="env-alert" @click="isHidden = !isHidden"
    :class="{ hiding: isHidden }"
  >
    <div class="toggle-icon">
      <font-awesome-icon :icon="['fal', 'chevron-down']" />
    </div>
    <div class="container">
      <span class="env"><strong>{{ environment | capitalize(true) }}</strong></span>
      <span class="partner-name">- {{ partner.name }}</span>
    </div>
  </div>
</template>

<script>

import environment from '../shared/mixins/environment';

export default {
  name: 'EnvAlert',

  mixins: [environment],

  data() {
    return {
      isHidden: false,
    };
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    showAlert() {
      return this.environment !== 'production' && this.partner.settings.show_environment_alert;
    },
  },
};

</script>

<style lang="scss" scoped>
  @import "../assets/stylesheets/components/env_alert";
</style>
