<template>
  <b-container class="error-screen">
    <div class="content-box constricted">
      <div class="m-4 text-center icon">
        <font-awesome-icon
          :icon="['fal', 'exclamation-square']"
          class="m-auto fa-4x"
          :class="objectAttr(errorContent.icon, 'class')"
          :style="objectAttr(errorContent.icon, 'style')"
        />
      </div>
      <h1
        class="text-center title m-3"
        :class="objectAttr(errorContent.title, 'class')"
        :style="objectAttr(errorContent.title, 'style')"
      >

        {{ objectAttr(errorContent.title, 'text') }}
      </h1>
      <span
        class="d-flex justify-content-center text-center d-flex description"
        :class="objectAttr(errorContent.description, 'class')"
        :style="objectAttr(errorContent.description, 'style')"
      >
        {{ objectAttr(errorContent.description, 'text') }}
      </span>
      <router-link
        :to="{ name: objectAttr(errorContent.cta, 'path_name') }"
        class="d-flex justify-content-center text-center content mb-3 mt-3 cta"
        :class="objectAttr(errorContent.cta, 'class')"
        :style="objectAttr(errorContent.cta, 'style')"
      >
        <u>
          {{ objectAttr(errorContent.cta, 'text') }}
        </u>
      </router-link>
    </div>
  </b-container>
</template>

<script>
import objectAttr from '../shared/mixins/object-attribute';

export default {
  name: 'ErrorScreen',

  mixins: [objectAttr],

  data() {
    return {
      defaultContent: {
        title: {
          text: 'There was an error fetching your Virtual Audit',
        },
        description: {
          text: 'Please try again in a few minutes. If the problem persists, contact a site administrator',
        },
        icon: {
          style: {
            color: 'red',
          },
        },
        cta: {
          text: 'Return to Your Buildings page',
          path_name: 'buildings-list',
        },
      },
    };
  },

  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  computed: {
    errorContent() {
      if (Object.keys(this.content).length === 0) return this.defaultContent;

      return { ...this.defaultContent, ...this.content };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/stylesheets/components/loading-screen";
</style>
