<template>
  <div>
    <p
      class="text-center dark-grey mb-0 pt-3 pb-2 sub-title"
      :class="currentGraph.title.class"
      :style="currentGraph.title.style"
    >
      {{ currentGraph.title.text }}
    </p>
    <div class="p-4 bar-chart-container mb-2 m-sm-4">
      <h4
        class="mb-0"
        :class="currentGraph.graph.title.class"
        :style="currentGraph.graph.title.style"
      >
        {{ currentGraph.graph.title.text }}
      </h4>
      <p
        class="mb-0 description"
        :class="currentGraph.graph.description.class"
        :style="currentGraph.graph.description.style"
      >
        {{ currentGraph.graph.description.text }}
      </p>
      <SavingsHorizontalBarChart
        class="horizontal-bar-chart pt-4"
        :currentChart="currentProps.data"
        :barStyle="currentGraph.graph.style.bar_color"
        :currentUnit="currentProps.unit"
        :currencyIcon="currencyIcon"
      />
    </div>

  </div>
</template>
<script>
import SavingsHorizontalBarChart from './SavingsHorizontalBarChart.vue';

export default {
  name: 'PotentialGraph',

  components: {
    SavingsHorizontalBarChart,
  },

  props: {
    currentProps: Object,
    currencyIcon: String,
  },

  computed: {
    graph() {
      return this.$store.state.partners.currentPartner.settings.sections
        .home_analysis_page.sub_sections.potential_savings.graphic2;
    },

    currentGraph() {
      if (this.currentProps.name === 'energy_savings') {
        return this.graph.energy_savings;
      }

      return this.graph.CO2_reductions;
    },
  },
};
</script>
