<template>
  <a :href="field.path" :class="customClasses" :style="customStyles" :target="targetContent">
    {{ field.text }}
  </a>
</template>

<script>

export default {
  name: 'FormExternalLinkField',

  props: {
    field: {
      type: Object,
      required: true,
    },

    customClasses: Array,
    customStyles: Object,
  },

  computed: {
    targetContent() {
      return this.field.openNewPage === true ? '_blank' : '';
    },
  },
};

</script>
