<template>
  <div class="p-sm-4 d-flex justify-content-center">
    <div class="section-container d-flex justify-content-between">
      <div class="section-template">
        <section-box
          :section="section"
          :id-prefix="idPrefix"
          :building="building"
          :subsections="section.sub_sections"
          :sectionsData="genericItems"
          :next-btn="true"
          :prev-btn="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SectionBox from '../components/SectionBox.vue';

export default {
  name: 'WaterHeatingSystem',

  components: {
    SectionBox,
  },

  data() {
    return {
      type: 'water_heating_system',
      idPrefix: 'water-heating-system',
    };
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    building() {
      return this.$store.state.buildings.currentBuilding;
    },

    section() {
      return this.partner.settings.sections.audit_sections[this.type][this.type];
    },

    genericItems() {
      return this.$store.state.generic_items.currentGenericItems;
    },
  },
};
</script>
