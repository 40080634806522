export default {
  computed: {
    currentPartner() {
      return this.$store.state.partners.currentPartner;
    },

    tagManagerID() {
      return this.currentPartner.settings.tag_manager?.id;
    },
  },

  methods: {
    async initializeGoogleTagManager() {
      // Enable Google Analytics if tag ID is present in settings
      const googleAnalyticsId = this.tagManagerID; // GTM-XXXXXXX
      if (googleAnalyticsId) {
        await this.activateGtm(window, document, 'script', 'dataLayer', googleAnalyticsId);
      } else {
        console.log('Analytics Tag ID is empty');
      }
    },

    async activateGtm(windowElement, documentElement, tagName, dataLayer, analyticsId) {
      // Add Tag Manager script to <head>

      // eslint-disable-next-line no-param-reassign
      const windowDataLayer = windowElement[dataLayer] || [];
      windowDataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      const outerScriptElement = documentElement.getElementsByTagName(tagName)[0];
      const innerScriptElement = documentElement.createElement(tagName);
      const dl = dataLayer !== 'dataLayer' ? `&l=${dataLayer}` : '';
      innerScriptElement.async = true;
      innerScriptElement.src = `https://www.googletagmanager.com/gtm.js?id=${analyticsId}${dl}`;
      outerScriptElement.parentNode.insertBefore(innerScriptElement, outerScriptElement);
    },
  },
};
