<template>
  <div class="w-100">
    <div class="w-100 text-center mt-4 egx-partner-color" v-html="contentHeader.header.title" />
    <div class="w-100 text-center limit-width" v-if="contentHeader.header.subtitle"
      v-html="contentHeader.header.subtitle" />
    <div class="w-100 text-center limit-width" v-html="contentHeader.header.description" />
    <div v-for="item in contentHeader.header.content.actions" v-if="!setAccountVerification && enableSignupIntegrated">
      <div class="text-center" v-if="item.type === 'link'">
        <router-link :class="item.custom_css.class" :style="item.custom_css.style" :to="item.path">
          {{ item.text }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PageHeader',

  props: {
    contentHeader: Object,
    setAccountVerification: Boolean,
    enableSignupIntegrated: Boolean
  },

  methods: {
    changeAccountVerification() {
      this.$emit('accountVerification');
    },
  },
};
</script>
