<template>
  <div>
    <h5
      v-if="field.label"
      class="pl-3 pt-3 pb-2 d-flex justify-content-center button-options-label"
      :class="labelClassObject"
    >
      {{ field.label }}
    </h5>
    <div>
      <div
        v-for="(item, index) in getOptions(field)"
        :key="index" class="btn col-12"
        :class="divClassObject"
      >
        <button
          :class="[
            (selectedList && selectedList.includes(item.name)) ? 'selected-option' : 'option',
            btnClassObject
          ]"
          @click.prevent="updateBtn($event, item)"
        >
          <span class="txt">{{ item.label }}</span>

          <div v-if="tooltip(item)">
            <span
              :id="`${field.name}-${item.name}-tooltip`"
              class="font-italic small egx-partner-color d-flex justify-content-center"
            >
              {{ tooltip(item).text }}
            </span>
            <b-tooltip
              :target="`${field.name}-${item.name}-tooltip`"
              triggers="hover"
              placement="top"
            >
              {{ tooltip(item).body }}
            </b-tooltip>
          </div>
        </button>
      </div>
      <div v-if="getOptions(field).length < 1">
        <h6 class="pl-4 pt-3 pb-2">
          {{ emptyOptionsMessage }}
        </h6>
      </div>
    </div>

    <div v-if="showSubField">
      <button-options
        :field="currentParentField.sub_fields"
        :multi-select="false"
        :sectionTitle="sectionTitle"
        :parentField="currentParentField"
        :isSubField="true"
        v-if="currentParentField.sub_fields.type === 'button-options'"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'buttonOptions',

  props: {
    field: Object,
    sectionTitle: String,
    parentField: Object,
    isSubField: Boolean,
  },

  mounted() {
    this.selectedList = (this.currentGenericItem && this.currentGenericItem.data
      && this.currentGenericItem.data[this.field.name]) || [];
    if (this.field.default && this.selectedList.length < 1) {
      this.selectedList = [this.field.default];
      const option = this.field.options.find((op) => op.name === this.field.default);
      this.updateBtn(null, option);
    }
    // eslint-disable-next-line prefer-destructuring
    this.currentTrigger = this.selectedList[0];
  },

  data() {
    return {
      selectedList: [],
      currentTrigger: '',
    };
  },

  watch: {
    parentField(newVal, oldVal) {
      if (oldVal.name !== newVal.name) this.selectedList = [];
    },

    selectedParentValue(newVal) {
      if (newVal
        && (this.getOptions(this.field).length === 0
        || !this.getOptions(this.field)
          .some((item) => this.currentGenericItemValue.includes(item.name)))
      ) {
        this.selectedList = []
        if (this.currentGenericItem) {
          this.currentGenericItem.data[this.field.name] = [];
        }
      }
    },
  },

  computed: {
    currentGenericItem() {
      return this.$store.state.generic_items.currentGenericItems[this.sectionTitle];
    },

    currentGenericItemValue() {
      return this.currentGenericItem?.data[this.field.name];
    },

    section() {
      return this.$store.state.partners.currentPartner.settings.sections[this.field.name];
    },

    currentBuilding() {
      return this.$store.state.buildings.currentBuilding;
    },

    configurations() {
      return this.field.configurations || {};
    },

    labelClassObject() {
      if (this.configurations.label_divider) return 'label-divider';
      if (this.isSubField) return 'sub-field-label';
      return 'd-md-block';
    },

    divClassObject() {
      if (this.configurations.small_buttons && !this.isSubField) return 'col-md-3';
      if (this.isSubField) return '';
      return 'col-md-6';
    },

    btnClassObject() {
      if (this.isSubField) return 'sub-field-btn btn-block p-2';

      if (this.configurations.small_buttons) {
        return 'option-small';
      }

      return 'option-normal';
    },

    currentParentField() {
      const { options } = this.field;

      return options.find((obj) => obj.name === this.currentTrigger);
    },

    hasSubField() {
      if (this.currentTrigger === '') return false;
      if (this.currentParentField === undefined) return false;

      return this.currentParentField.sub_fields !== undefined;
    },

    hideSubField() {
      if (this.currentParentField === undefined) return true;
      return this.currentParentField.sub_fields.configurations.hidden;
    },

    showSubField() {
      return this.field.enable_sub_fields && this.hasSubField && !this.hideSubField;
    },

    linkedParent() {
      return this.field.linked_parent?.split('#');
    },

    linkedParentGenItem() {
      return this.$store.state.generic_items
        .currentGenericItems[this.linkedParent?.[0]]?.data[this.linkedParent?.[1]];
    },

    emptyOptionsMessage() {
      return this.field.empty_options_message || 'None';
    },

    selectedParentValue() {
      if (!this.linkedParent) {
        return null;
      }

      return this.linkedParentGenItem;
    },
  },

  methods: {
    tooltip(item) {
      return item.tooltip ? item.tooltip : false;
    },

    updateBtn(event, item) {
      this.currentTrigger = item.name;
      const newCurrentGenericItem = this.$store.state.generic_items.currentGenericItems[
        this.sectionTitle
      ];

      if (this.parentField) {
        const otherChildFieldNames = this.getOtherChildField();
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < otherChildFieldNames.length; i++) {
          const name = otherChildFieldNames[i];
          newCurrentGenericItem.data[name] = [];
        }
      }

      this.selectedList = this.selectedList.reduce((previous, filterItem) => {
        if (previous.includes(filterItem)) return previous;

        return [...previous, filterItem];
      }, []);

      if (
        this.field.configurations === undefined
        || this.field.configurations.multi_selection !== true
        || !(newCurrentGenericItem.data[this.field.name] instanceof Array)
      ) {
        if (newCurrentGenericItem) {
          newCurrentGenericItem.data[this.field.name] = [item.name];
        }

        if (this.field.enable_sub_fields) {
          this.field.options.forEach((obj) => {
            if (newCurrentGenericItem) {
              newCurrentGenericItem.data[obj.sub_fields.name] = [];
            }

            if (obj.sub_fields.enable_sub_fields) {
              const childNames = obj.sub_fields.options.map((childObj) => childObj.sub_fields.name);

              childNames.forEach((childName) => {
                newCurrentGenericItem.data[childName] = [];
              });
            }
          });
        }

        if (item.auto_select && newCurrentGenericItem) {
          newCurrentGenericItem.data[item.sub_fields.name] = [item.sub_fields.options[0].name];
        }

        this.selectedList = [item.name];
      } else if (!newCurrentGenericItem.data[this.field.name].includes(item.name)) {
        newCurrentGenericItem.data[this.field.name].push(item.name);
        this.selectedList.push(item.name);
      } else {
        newCurrentGenericItem.data[this.field.name].splice(
          newCurrentGenericItem.data[this.field.name].indexOf(item.name), 1,
        );
        this.selectedList.splice(this.selectedList.indexOf(item.name), 1);
      }

      const obj = { key: this.field.name, value: newCurrentGenericItem };
      this.$store.dispatch('generic_items/updateSectionData', obj);
    },

    getOtherChildField() {
      const allSubFields = this.parentField.sub_fields;
      const fieldNames = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < allSubFields.length; i++) {
        if (allSubFields[i].trigger !== this.field.trigger) {
          fieldNames.push(allSubFields[i].name);
        }
      }

      return fieldNames;
    },

    getOptions(field) {
      return field.options.filter((item) => this.allowDisplayForLinkedParent(item));
    },

    allowDisplayForLinkedParent(item) {
      if (!item.linked_parent_for
        || !this.linkedParent
        || item.linked_parent_for.some((parent) => this.linkedParentGenItem?.includes(parent))) {
        return true;
      }

      return false;
    },
  },
};
</script>
