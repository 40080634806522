<template>
  <div>
    <canvas ref="barChart" :class="this.config.class"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';
import 'chartjs-plugin-datalabels';

export default {
  data() {
    return {
      chart: null,
    };
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    moneyType: {
      type: String,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    chartData: {
      handler: 'updateChart',
      deep: true,
    },
  },
  methods: {
    renderChart() {
      const ctx = this.$refs.barChart.getContext('2d');
      const moneyType = { ...this.moneyType };
      const config = { ...this.config };
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: this.chartData,
        options: {
          plugins: {
            datalabels: {
              color: config.datalable_color,
            },
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: config.y_axes_begin_at_zero,
                  maxTicksLimit: config.y_axes_max_ticks_limit,
                  callback: function formatAxisLabel(value) {
                    return moneyType[0] ? `${moneyType[0]} ${value}` : value;
                  },
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontColor: config.labels_color || 'rgb(119,226,234)',
                  fontStyle: 'bold',
                  fontFamily: 'Arial',
                  fontSize: config.labels_font_size || 14,
                },
              },
            ],
          },
        },
      });
    },
    updateChart() {
      if (this.chart) {
        this.chart.data = this.chartData;
        this.chart.update();
      }
    },
  },
};
</script>
