import Vue from 'vue';
// eslint-disable-next-line import/no-cycle
import ajax from '../../ajax';

export default {
  namespaced: true,

  subsidies: {
    allSubsidies: [],
    currentSubsidies: [],
    allIncentiveTags: [],
    appliedIncentiveTags: [],
    softRecommendedSubsidies: [],
    isLoading: false,
  },

  mutations: {
    setAll(state, data) {
      state.allSubsidies = data;
    },

    set(state, data) {
      state.currentSubsidies = data;
    },

    setAllTags(state, data) {
      Vue.set(state, 'allIncentiveTags', data);
    },

    setAppliedTags(state, data) {
      Vue.set(state, 'appliedIncentiveTags', data);
    },

    setSoftRecSubsidies(state, data) {
      state.softRecommendedSubsidies = data;
    },
  },

  actions: {
    async getAll({ commit, dispatch, state }) {
      state.isLoading = true;
      const params = { building_id: this.state.buildings?.currentBuilding?.id };

      await ajax.get('/api/v1/subsidies', { params })
        .then((response) => {
          commit('setAll', response.data.data);
          dispatch('setSoftRecommendedSubsidies');
          dispatch('setAllIncentiveTags', []);
          dispatch('setAppliedIncentiveTags', []);
          state.isLoading = false;
        })
        .catch((errors) => {
          console.error(errors);
          state.isLoading = false;
        });
    },

    setAllIncentiveTags({ commit }, data) {
      commit('setAllTags', data);
    },

    setAppliedIncentiveTags({ commit }, data) {
      commit('setAppliedTags', data);
    },

    setSoftRecommendedSubsidies({ commit }) {
      const subsidies = this.state.subsidies.allSubsidies
        .filter((obj) => this.state.partners.currentPartner
          .soft_recommended_subsidies.includes(obj.attributes.id));

      commit('setSoftRecSubsidies', subsidies);
    },

    // eslint-disable-next-line no-empty-pattern
    getDescription({}, id) {
      return ajax.get(`/api/v1/subsidy_description/${id}`)
        .then((response) => (response))
        .catch((errors) => console.error(errors));
    },

    clean({ commit }) {
      commit('setAll', []);
      commit('set', {});
      commit('setAllTags', []);
      commit('setAppliedTags', []);
      commit('setSoftRecSubsidies', []);
    },
  },
};
