<template>
  <ValidationProvider :rules="this.field.validations" v-slot="{ errors }" class="input-group-lg">
     <span
       class="ml-2"
       :class="field.label_class"
       v-if="field.show_label"
     >
      {{ field.label }}
    </span>
    <select
      :id="idPrefix + '-' + field.name" class="form-control select-field"
      :name="field.name" v-model="model[field.name]"
      :value="updateDefaultField(field.name, model[field.name], field.default)"
      @input="$emit(field.name, $event.target.value)"
      :class="placeholder" :disabled="isDisabled"
      @change="removeNotValidClass($event.target.value)"
    >
      <option disabled value="">{{ selectMenuPlaceholder() }}</option>
      <option v-for="(text, value) in fieldOptions" :value="value" :key="value">
        {{ text }}
      </option>
    </select>
    <span>{{ errMessage(errors[0]) }}</span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'SelectField',

  components: {
    ValidationProvider,
  },

  props: {
    idPrefix: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    model: {
      type: Object,
      required: true,
    },

    isCopyAddress: Boolean,
  },

  data() {
    return {
      notValidClass: '',
      isCopyAddressDisabled: false,
    };
  },

  mounted() {
    this.setModel();
  },

  watch: {
    isCopyAddress() {
      if (this.field.copy_address !== undefined && this.isCopyAddress) {
        if (this.currentUser[this.field.copy_address] !== undefined) {
          this.model[this.field.name] = this.currentUser[this.field.copy_address];
        } else {
          this.model[this.field.name] = this.currentUser.extra_info[this.field.copy_address];
        }
        this.isCopyAddressDisabled = true;
      } else {
        this.isCopyAddressDisabled = false;
      }
    },
  },

  methods: {
    selectMenuPlaceholder() {
      if (this.field.placeholder) {
        return this.field.placeholder;
      }

      return 'Please select one';
    },

    setModel() {
      if (this.model[this.field.name] === undefined) {
        this.model[this.field.name] = '';
      }

      return this.model[this.field.name];
    },

    updateDefaultField(fieldName, value, defaultValue) {
      const name = value || defaultValue;
      this.$set(this.model, fieldName, name);
      return name;
    },

    errMessage(error) {
      if (error !== undefined) {
        this.notValidClass = 'box-alert';
      }

      return undefined;
    },

    removeNotValidClass(value) {
      if (value !== '') {
        this.notValidClass = '';
      }
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.users.currentUser;
    },

    placeholder() {
      if (this.model[this.field.name] === '') {
        return `text-black-50 ${this.notValidClass}`;
      }

      return '';
    },

    fieldOptions() {
      if (this.field.parent !== undefined) {
        const parentValue = this.model[this.field.parent];

        /*
         * `inverse_options` means that options in the select field
         * are the keys of `this.field.options` if that keys' array
         * contains the value of the parent field.
         * Ex.
         * inverse_options = true
         * this.field.options = { "One": ["1"], "Two": ["1", "2"], "Three": ["2", "3"] }
         * parentValue = "1"
         * options are "One" and "Two" since those keys contain "1"
         */

        if (this.field.inverse_options) {
          const options = {};
          Object.keys(this.field.options).forEach((key) => {
            if (this.field.options[key].includes(parentValue)) options[key] = key;
          });
          return options;
        }

        /*
         * `options` is an object that returns the object within the key matching
         * the value of the parent field.
         */
        return this.field.options[parentValue];
      }

      return this.field.options;
    },

    isDisabled() {
      return this.field.disabled === 'true' || this.isCopyAddressDisabled;
    },
  },
};
</script>

<style type="scss" scoped>
@import '../../assets/stylesheets/components/form/select_field.scss';
</style>
