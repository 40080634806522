<template>
  <div class="container">
    <loading-screen v-if="isLoading" :content="loadingScreenContent" />
    <error-screen v-else-if="virtualAuditError" :content="errorScreenContent" />

    <div v-else class="pt-5 pb-5">
      <h1
        class="text-center p-4"
        :class="homeAnalysis.title.class"
        :style="homeAnalysis.title.style"
      >
        {{ homeAnalysis.title.text }}
      </h1>
      <p
        class="text-center home-page-body-text"
        :class="homeAnalysis.description.first.class"
        :style="homeAnalysis.description.first.style"
      >
        {{ homeAnalysis.description.first.text }}
      </p>
      <div
        class="text-center rectangular-box p-2 mt-5 mb-5"
        :class="homeAnalysis.description.second.background.class"
        :style="homeAnalysis.description.second.background.style"
      >
        <p
          class="mb-0"
          :class="homeAnalysis.description.second.class"
          :style="homeAnalysis.description.second.style"
          id="home-analysis-top-alert-banner"
          v-html="subBuildingAttr(this.currentBuilding, this.settings.application_top_alert)"
        />
      </div>

      <div
        v-if="homeAnalysis.sub_sections.potential_savings.return_to_audit_cta"
        class="rectangular-border text-center p-2"
        :class="homeAnalysis.sub_sections.potential_savings.return_to_audit_cta.border.class"
        :style="homeAnalysis.sub_sections.potential_savings.return_to_audit_cta.border.style"
      >
        <router-link
          :to="{
            name: homeAnalysis.sub_sections.potential_savings.return_to_audit_cta.path_name
          }"
          class="thicker-font-weight"
          :class="homeAnalysis.sub_sections.potential_savings.return_to_audit_cta.class"
          :style="homeAnalysis.sub_sections.potential_savings.return_to_audit_cta.style"
        >
          {{ homeAnalysis.sub_sections.potential_savings.return_to_audit_cta.text }}
        </router-link>
      </div>

      <div class="pt-3">
        <roadmap
          :home-analysis="homeAnalysis"
          :current-virtual-audit="currentVirtualAudit"
          :hideEnergySaving="hideEnergySavings"
          :reportSummary="reportSummary"
          v-if="
            !homeAnalysis.sub_sections.roadmap.hide &&
            homeAnalysis.sub_sections.roadmap.position === 'top'
          "
        />
      </div>

      <div v-html="homeAnalysis.sub_sections.potential_savings.above_title_html" />

      <h3
        class="text-center pt-5"
        :class="homeAnalysis.sub_sections.potential_savings.title.class"
        :style="homeAnalysis.sub_sections.potential_savings.title.style"
      >
        {{ homeAnalysis.sub_sections.potential_savings.title.text }}
      </h3>
      <h5
        class="text-center"
        :class="homeAnalysis.sub_sections.potential_savings.sub_title.class"
        :style="homeAnalysis.sub_sections.potential_savings.sub_title.style"
        v-if="homeAnalysis.sub_sections.potential_savings.sub_title"
      >
        {{ homeAnalysis.sub_sections.potential_savings.sub_title.text }}
      </h5>

      <div v-if="!homeAnalysis.sub_sections.potential_savings.graphic1.hide">
        <SavingsBarChart
          :data="currentVirtualAudit.consumptions"
          class="pt-5 pb-5 home-analysis-energy-savings-chart"
        />
        <building-overview-link
          :home-analysis="homeAnalysis"
          v-if="homeAnalysis.sub_sections.roadmap.position != 'top'"
          class="mb-4"
        />
      </div>

      <div v-if="homeAnalysis.external_link.enable" class="rectangular-border mt-2">
        <b-button
          class="d-flex justify-content-center m-auto btn-block"
          :style="homeAnalysis.external_link.style"
          @click="singleCtaBtnOnClick()"
        >
          {{ homeAnalysis.external_link.text }}
        </b-button>
      </div>

      <div v-if="!homeAnalysis.sub_sections.potential_savings.graphic2.hide">
        <keep-alive>
          <PotentialGraph :currentProps="currentProps" :currencyIcon="currencyIcon" />
        </keep-alive>
        <div class="text-center">
          <span
            class="arrow-btn"
            @click="swapGraph('energy_savings')"
          >
            <font-awesome-icon :icon="['fas', 'arrow-left']" class="m-1" />
          </span>
          <span
            class="dot-btn m-1"
            @click="swapGraph('energy_savings')"
            :class="{
              'savings-active': savingsActive,
              'dot-btn-inactive': !savingsActive
            }"
          />
          <span
            class="dot-btn m-1"
            @click="swapGraph('CO2_reductions')"
            :class="{
              'reduction-active': reductionActive,
              'dot-btn-inactive': !reductionActive
            }"
          />
          <span
            class="arrow-btn"
            @click="swapGraph('CO2_reductions')"
          >
            <font-awesome-icon :icon="['fas', 'arrow-right']" class="m-1" />
          </span>
        </div>
        <building-overview-link
          :home-analysis="homeAnalysis"
          class="mt-5"
          v-if="homeAnalysis.sub_sections.roadmap.position === 'top'"
        />
      </div>
      <div class="text-center pt-3">
        <roadmap
          :home-analysis="homeAnalysis"
          :current-virtual-audit="currentVirtualAudit"
          :hideEnergySaving="hideEnergySavings"
          :reportSummary="reportSummary"
          v-if="
            !homeAnalysis.sub_sections.roadmap.hide &&
            homeAnalysis.sub_sections.roadmap.position != 'top'
          "
        />
        <div v-if="homeAnalysis.external_link.enable" class="rectangular-border mt-2">
          <b-button
            class="d-flex justify-content-center m-auto btn-block"
            :style="homeAnalysis.external_link.style"
            @click="singleCtaBtnOnClick()"
          >
            {{ homeAnalysis.external_link.text }}
          </b-button>
        </div>
      </div>
      <div
        v-if="objectAttr(homeAnalysis.sub_sections.disclaimer, 'text')"
        class="text-center pt-5"
        :class="objectAttr(homeAnalysis.sub_sections.disclaimer, 'class')"
        :style="objectAttr(homeAnalysis.sub_sections.disclaimer, 'style')"
      >
        {{ objectAttr(homeAnalysis.sub_sections.disclaimer, 'text') }}
      </div>
    </div>
  </div>
</template>

<script>
import ErrorScreen from '@/components/ErrorScreen.vue';
import SavingsBarChart from '../components/SavingsBarChart.vue';
import PotentialGraph from '../components/PotentialGraph.vue';
import LoadingScreen from '../components/LoadingScreen.vue';
import BuildingAddress from '../shared/mixins/building-address';
import objectAttr from '../shared/mixins/object-attribute';
import Roadmap from '../components/Roadmap.vue';
import BuildingOverviewLink from '../components/BuildingOverviewLink.vue';

export default {
  name: 'HomeAnalysis',

  mixins: [
    BuildingAddress,
    objectAttr,
  ],

  components: {
    ErrorScreen,
    SavingsBarChart,
    PotentialGraph,
    LoadingScreen,
    Roadmap,
    BuildingOverviewLink,
  },

  data() {
    return {
      currentHorizontalGraph: 'energy_savings',
      savingsActive: true,
      reductionActive: false,
      isLoading: false,
      virtualAuditError: false,
      addressElements: ['address1', 'address2', 'zip', 'community', 'region'],
    };
  },

  mounted() {
    this.isLoading = true;

    this.$store.dispatch('virtual_audits/post', this.currentBuilding.id)
      .then(async () => {
        await this.$store.dispatch('buildings/set', this.currentBuilding.id);
      })
      .then(() => {
        this.isLoading = false;
      }) // show page once loaded
      .catch((err) => {
        this.isLoading = false;
        this.virtualAuditError = true;
        console.error(err);
      });
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    currentBuilding() {
      return this.$store.state.buildings.currentBuilding;
    },

    settings() {
      return this.partner.settings;
    },

    homeAnalysis() {
      return this.settings.sections.home_analysis_page;
    },

    currentProps() {
      if (this.currentHorizontalGraph === 'energy_savings') {
        return {
          name: 'energy_savings',
          data: this.currentVirtualAudit.savings.money,
          unit: '$',
        };
      }

      return {
        name: 'CO2_reductions',
        data: this.currentVirtualAudit.savings.ghg,
        unit: this.homeAnalysis.sub_sections.potential_savings.graphic2.CO2_reductions
          .graph.tooltip.unit || 'kg',
      };
    },

    currentVirtualAudit() {
      return this.$store.state.virtual_audits.currentVirtualAudit;
    },

    loadingScreenContent() {
      return this.homeAnalysis.configurations.loading_screen_configurations;
    },

    errorScreenContent() {
      return this.settings.sections.error_page;
    },

    currencyIcon() {
      return this.partner.currency_icon;
    },

    hideEnergySavings() {
      return this.partner.settings.hide_energy_savings_in_reports;
    },
    reportSummary() {
      return this.settings.energy_savings_report_summary_result;
    },
  },

  methods: {
    swapGraph(name) {
      this.currentHorizontalGraph = name;

      if (name === 'energy_savings') {
        this.savingsActive = true;
        this.reductionActive = false;
      } else {
        this.savingsActive = false;
        this.reductionActive = true;
      }
    },

    singleCtaBtnOnClick() {
      window.open(this.homeAnalysis.external_link.url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/stylesheets/pages/home_analysis.scss';
@import '../assets/stylesheets/partials/_general.scss';
</style>
