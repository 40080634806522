export default {
  namespaced: true,

  state: {
    currentLocale: 'en',
    currentTester: '',
  },

  mutations: {
    setCurrentLocale(state, data) {
      state.currentLocale = data;
    },
    setCurrentTester(state, data) {
      state.currentTester = data;
    },
  },

  actions: {
    setCurrentLocale({ commit }, locale) {
      commit('setCurrentLocale', locale);
    },

    setCurrentTester({ commit }, tester) {
      commit('setCurrentTester', tester);
    },

    clean({ commit }) {
      commit('setCurrentLocale', '');
      commit('setCurrentTester', '');
    },
  },
};
