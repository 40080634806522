<template>
  <div class="notice_section" v-if="noticeDetails">
    <b-alert
      show
      class="text-left notice-area my-4"
      :class="noticeDetails.class"
      :style="noticeDetails.style"
    >
      <div
        class="notice-header"
        :class="noticeDetails.header.class"
        :style="noticeDetails.header.style"
      >
        <span class="header-text">
          <font-awesome-icon
            :icon="['fal', 'exclamation-square']"
            class="m-auto exclamation-square"
          />
          {{ noticeDetails.header.text }}
        </span>
      </div>
      <div
        class="p-2 text-justify"
        :class="noticeDetails.body.class"
        :style="noticeDetails.body.style"
        v-html="noticeDetails.body.text"
      />
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'NoticeSection',

  props: {
    noticeDetails: Object,
  },
};
</script>

<style lang="scss" scoped>
  @import "../assets/stylesheets/components/notice_section";
</style>
