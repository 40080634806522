export default {
  computed: {
    currentPartner() {
      return this.$store.state.partners.currentPartner;
    },

    localizeKey() {
      return this.currentPartner.settings.localize?.key;
    },

    localizeStatus() {
      return this.currentPartner.settings.localize?.enable;
    },

    localizeTesterEmails() {
      if (!this.currentPartner?.settings?.localize) return [];

      return this.currentPartner.settings.localize.tester_emails
        .split(',')
        .filter((email) => email !== '')
        .map((email) => email.trim());
    },

    enableLocalizeForAll() {
      // Check if localize is enabled for everyone
      return this.localizeStatus === 'enabled' && this.localizeKey;
    },

    enableLocalizeForTesting() {
      // Basic checks if testing is enabled
      if (!(this.localizeStatus === 'testing' && this.localizeKey && this.localizeTesterEmails.length > 0)) {
        return false;
      }

      // Only users with a tester email address can test Localize
      // If user is logged out, this will fail
      const currUserEmail = this.$store.state.users.currentUser?.email;
      if (this.localizeTesterEmails.includes(currUserEmail)) {
        this.$store.dispatch('locale/setCurrentTester', currUserEmail);
        return true;
      }

      // Fallback in case user is logged out. Check with last available tester email if present
      if (this.$store.state.locale.currentTester !== '') {
        return this.localizeTesterEmails.includes(this.$store.state.locale.currentTester);
      }

      return false;
    },

    enableLocalize() {
      return this.enableLocalizeForAll || this.enableLocalizeForTesting;
    },
  },

  methods: {
    initializeLocalizeTag() {
      if (!this.enableLocalize) return;

      console.log('Initializing Localize');
      // eslint-disable-next-line no-undef
      Localize.initialize({
        key: this.localizeKey,
        rememberLanguage: true,
        saveNewPhrasesFromSource: true,
        allowInlineBreakTags: true,
        // other options go here, separated by commas
      });

      // eslint-disable-next-line no-undef
      Localize.on('setLanguage', ({ to }) => {
        // Ensure language updated in store when changed in Localize
        this.$store.dispatch('locale/setCurrentLocale', to);
      });
    },
  },
};
