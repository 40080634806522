<template>
  <div class="w-100">
    <div class="w-100 text-center mt-4" v-html="pageSection.header.content.title" />
    <div class="w-100 text-center" v-if="pageSection.header.content.subtitle"
      v-html="pageSection.header.content.subtitle" />
    <div class="mt-4" v-html="pageSection.header.content.description" />

    <div class="row">
      <div class="form-group input-group-lg col-md-12"
        v-for="(field, index) in pageSection.content.content.fields" :key="index" v-show="!field.hidden">
        <form-input-field :id-prefix="idPrefix"
          :field="{...field, disabled: (messageObj.type == 'success' && messageObj.display)}"
          :model="model" :disabled="true" />
      </div>
    </div>
    <div v-for="message in pageSection.content.content.messages">
      <div class="alert alert-warning text-center" :class="message.custom_css.class" :style="message.custom_css.style"
        v-html="message.text"
        v-if="message.type == 'userExist' && messageObj.display && messageObj.type == 'userExist' && message.text"/>
      <div class="alert alert-warning text-center" :class="message.custom_css.class" :style="message.custom_css.style"
        v-html="message.text"
        v-if="message.type == 'warning' && messageObj.display && messageObj.type == 'warning'  && message.text" />
      <div class="alert alert-success text-center" :class="message.custom_css.class" :style="message.custom_css.style"
        v-html="message.text"
        v-if="message.type == 'success' && messageObj.display && messageObj.type == 'success'  && message.text" />
    </div>
    <div v-if="sectionType == 'account_verification'">
      <div v-for="item in pageSection.content.content.actions" :key="item.id">
        <div class="mt-4 text-center" v-html="item.description_top" />
        <div class="text-center" v-if="item.type === 'link'">
          <router-link :class="item.custom_css.class" :style="item.custom_css.style" :to="item.path"
            @click.native="returnTosignup(item.path)">
            {{ item.text }}
          </router-link>
        </div>
        <button
          type="button"
          class="btn btn-lg d-flex ml-auto mr-auto mt-5"
          :class="item.custom_css.class"
          :style="item.custom_css.style"
          v-else-if="!ssoIssuerLoginOnly && item.type === 'submit' && ((messageObj.display && messageObj.type == 'warning') || !messageObj.display)"
          @click.prevent="findAccount"
        >
          {{ item.text }}
        </button>
        <button
          type="button"
          class="btn btn-lg d-flex ml-auto mr-auto mt-5"
          :class="item.custom_css.class"
          :style="item.custom_css.style"
          v-if="!ssoIssuerLoginOnly && item.type === 'submit' && messageObj.display && !novaScotiaAccountExist"
          @click.prevent="findAccount"
        >
          Change Account Verification Message
        </button>
      </div>
    </div>
    <div v-if="sectionType == 'account_creation'">
      <div v-for="item in pageSection.content.content.actions" :key="item.id">
        <div class="mt-4 text-center" v-html="item.description_top"/>
        <div class="text-center" v-if="item.type === 'link'">
          <router-link
            :class="item.custom_css.class"
            :style="item.custom_css.style"
            :to="item.path"
          >
            {{ item.text }}
          </router-link>
        </div>
        <button
          type="submit"
          class="btn btn-lg d-flex ml-auto mr-auto mt-5"
          :class="item.custom_css.class"
          :style="item.custom_css.style"
          v-else-if="!ssoIssuerLoginOnly && item.type === 'submit'"
        >
          {{ item.text }}
        </button>
        <div class="mt-4 text-center" v-html="item.description_bottom"/>
      </div>
    </div>
  </div>
</template>

<script>
import FormField from '../form/FormField.vue';
import FormInputField from '../form/InputField.vue';

export default {  
  name: 'PageSectionWithForm',

  components: {
    FormField,
    FormInputField,
  },
  props: {
    pageSection: Object,
    model: Object,
    messageObj: Object,
    sectionType: String,
    novaScotiaAccountExist: Boolean,
    ssoIssuerLoginOnly: Boolean,
    idPrefix: String
  },
  methods: {
    findAccount() {
      this.$emit('findAccount');
    },
    returnTosignup(){
      this.$emit('returnTosignup');
    }
  },
};
</script>
