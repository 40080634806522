<template>
  <SignUp v-if="!enableSignupIntegrated"/>
  <SignUpIntegrated v-else />
</template>

<script>
import SignUp from '../../components/SignUp.vue';
import SignUpIntegrated from '../../components/SignUpIntegrated.vue';

export default {
  name: 'SignUpCentral',

  components: {
    SignUp,
    SignUpIntegrated
  },

  mounted() {},

  data() {
    return {};
  },

  methods: {},

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    enableSignupIntegrated() {
      return this.partner.settings.enable_sign_up_integrated;
    },
  },
};
</script>
