<template>
  <button type="button" :class="customClasses" :style="customStyles" @click="triggerFunction">
    {{ field.text }}
  </button>
</template>

<script>

export default {
  name: 'ButtonField',

  props: {
    field: {
      type: Object,
      required: true,
    },

    triggerFunction: {
      type: Function,
      required: true,
    },

    customClasses: Array,
    customStyles: Object,
  },
};

</script>
