<template>
  <div class="incentives col-12 p-4 mt-5">
    <loading-screen v-if="this.isLoading || isIncentivesLoading" :content="loadingScreenContent" />
    <div class="row col-md-12 col-lg-8 mx-auto" v-else>
      <div class="col-12">
        <header>
          <h1
            class="text-center egx-partner-color mt-3"
            :class="incentivesPageSettings.attributes.title.class"
            :style="incentivesPageSettings.attributes.title.style"
          >
            {{ incentivesPageSettings.attributes.title.text }}
          </h1>
          <h5
            class="p-4 d-flex justify-content-center text-black-50"
            :class="incentivesPageSettings.attributes.subtitle.class"
            :style="incentivesPageSettings.attributes.subtitle.style"
          >
            {{ incentivesPageSettings.attributes.subtitle.text }}
          </h5>
        </header>
      </div>

      <!-- Applied incentives -->
      <incentive-section
        :incentivesArray="appliedIncentives"
        :incentivesType="'applied'"
        :tagStoreName="'appliedIncentiveTags'"
        :sectionSettings="subSectionSettings['applied_incentives']"
        :show-filter="true"
        :isOffer="this.useOffer"
      />

      <!-- Available incentives -->
      <incentive-section
        :incentivesArray="availableIncentives"
        :incentivesType="'available'"
        :tagStoreName="'allIncentiveTags'"
        :sectionSettings="subSectionSettings['available_incentives']"
        :show-filter="true"
      />
    </div>
  </div>
</template>

<script>
import IncentiveSection from '../components/IncentivesSection.vue';
import emptyObjectMixin from '../shared/mixins/empty-object';
import LoadingScreen from '../components/LoadingScreen.vue';

export default {
  name: 'Incentives',

  mixins: [emptyObjectMixin],

  components: {
    IncentiveSection,
    LoadingScreen,
  },

  created() {
    const storeType = this.useOffer ? 'offers/getAll' : 'subsidies/getAll';
    const selectedBuildingId = this.currentBuilding.id || this.currentBuilding.selectedId;

    this.$store.dispatch('buildings/set', selectedBuildingId).then(async () => {
      await this.$store.dispatch(storeType).then(() => {
        this.$store.dispatch('virtual_audits/get', this.currentBuilding.id).then(() => {
          this.isLoading = false;
        });
      });
    });
  },

  data() {
    return {
      type: 'Incentives',
      isLoading: true,
    };
  },

  computed: {
    allIncentives() {
      // This name can be changed after to allOffers
      if (this.useOffer) {
        return this.$store.state.offers.allOffers;
      }

      return this.$store.state.subsidies.allSubsidies;
    },

    partner() {
      return this.$store.state.partners.currentPartner;
    },

    useOffer() {
      return this.partner.settings.energyxpert_admin_enabled;
    },

    appliedIncentives() {
      return this.allIncentives.filter(
        (incentive) => this.isApplied(incentive) && incentive.attributes.is_recommended === false,
      );
    },

    availableIncentives() {
      return this.allIncentives.filter((incentive) => this.eaAssignmentAvailable(incentive)
        && this.isNotApplied(incentive) && incentive.attributes.is_recommended === false
        && this.withinDateRange(incentive) && incentive.attributes.is_active === true
        && incentive.attributes.is_triggered !== false);
    },

    incentivesPageSettings() {
      return this.$store.state.partners.currentPartner.settings.sections.incentives;
    },

    subSectionSettings() {
      return this.incentivesPageSettings.attributes.sub_sections;
    },

    currentBuilding() {
      return this.$store.state.buildings.currentBuilding;
    },

    eaAssignment() {
      return this.currentBuilding.ea_assignment;
    },

    loadingScreenContent() {
      return this.incentivesPageSettings.configurations.loading_screen_configurations;
    },

    isIncentivesLoading() {
      // This name can be changed after to isOffersLoading
      if (this.useOffer) {
        return this.$store.state.offers.isLoading;
      }

      return this.$store.state.subsidies.isLoading;
    },
  },

  methods: {
    eaAssignmentAvailable(incentive) {
      if (incentive.attributes.quantity_limitation_active) {
        return this.$store.getters['offers/checkAvailableQuantity'](incentive);
      }

      return !this.eaAssignment[incentive.attributes.project_type?.id];
    },

    isNotApplied(incentive) {
      // TODO should change the params to offer later, this part refers to offer
      if (incentive.attributes.quantity_limitation_active) {
        // getter return true for available
        return this.$store.getters['offers/checkAvailableQuantity'](incentive);
      }

      const projectType = incentive.attributes.project_type;
      const rebateProjects = this.currentBuilding.rebate_project;
      const project = rebateProjects.find((p) => p.project_type?.id === projectType?.id);

      return !project;
    },

    isApplied(incentive) {
      const projectType = incentive.attributes.project_type;
      const { id } = incentive.attributes;
      const rebateProjects = this.currentBuilding.rebate_project;
      const programmableType = this.useOffer ? 'Offer' : 'Subsidy';

      const project = rebateProjects.find(
        (p) => (p.project_type?.id === projectType?.id)
          && (p.programmable_type === programmableType)
          && (p.programmable_id === id || p.original_programmable_id === id),
      );

      return !!project;
    },

    withinDateRange(incentive) {
      return this.$store.getters['offers/checkWithinDateRange'](incentive);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/pages/incentives';
</style>
