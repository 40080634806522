<template>
    <fieldset :id="idPrefix + '-' + field.name" class="radio-boxes" :name="field.name">
      <div class="radio" v-for="(option, value) in field.options" :key="value">
        <label>
          <input type="radio" :value="value" :name="field.field" v-model="model[field.name]">
          {{ option }}
        </label>
      </div>
    </fieldset>

</template>

<script>

export default {
  name: 'FieldsetField',

  components: {
  },

  props: {
    idPrefix: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    model: {
      type: Object,
      required: true,
    },
  },
};

</script>
