<template>
  <b-modal
    id="modal-page-transition"
    :size="'lg'"
    centered
    hide-footer
    hide-header
  >
    <div class="modal-page-transition">
      <header
        class="modal-page-transition-header mb-2"
        :class="modalHeaderData.class"
        :style="modalHeaderData.style"
      >
        <div class="row close-line d-flex justify-content-around">
          <div
            class="text-section col-10"
            :class="modalHeaderData.textSection.class"
            :style="modalHeaderData.textSection.style"
          >
            {{ modalHeaderData.textSection.text }}
          </div>

          <div
            class="close-section d-flex col-2 justify-content-end align-items-start"
            :class="modalHeaderData.closeSection.class"
            :style="modalHeaderData.closeSection.style"
          >
            <img
              src="@/assets/images/icons/close.svg"
              class="btn-close-modal align-items-end"
              :class="modalHeaderData.closeSection.closeImage.class"
              :style="modalHeaderData.closeSection.closeImage.style"
              @click="closeModal"
            />
          </div>
        </div>
      </header>

      <section
        class="row modal-page-transition-section justify-content-center"
        :class="modalSectionData.class"
        :style="modalSectionData.style"
      >
        <header
          class="col-8 mb-3"
          :class="modalSectionData.header.class"
          :style="modalSectionData.header.style"
        >
          <h3
            class="section-title"
            :class="modalSectionData.header.title.class"
            :style="modalSectionData.header.title.style"
          >
            {{ modalSectionData.header.title.text }}
          </h3>
        </header>

        <div
          class="section-content col-8 mb-5"
          :class="modalSectionData.section.class"
          :style="modalSectionData.section.style"
        >
          {{ modalSectionData.section.text }}
        </div>

        <footer
          class="section-footer col-8"
          :class="modalSectionData.footer.class"
          :style="modalSectionData.footer.style"
        >
          <button-field
            :field="modalSectionData.footer.advancedModeButton"
            :custom-classes="modalSectionData.footer.advancedModeButton.class"
            :custom-styles="modalSectionData.footer.advancedModeButton.style"
            :trigger-function="modalSectionData.footer.advancedModeButton.triggerFunction"
          />
        </footer>
      </section>

      <footer
        class="row modal-page-transition-footer mt-3 mb-3"
        :class="modalFooterData.class"
        :style="modalFooterData.style"
      >
        <div class="col-12">
          <router-span-field
            :field="modalFooterData.homeReportLink"
            :custom-classes="modalFooterData.homeReportLink.class"
            :custom-styles="modalFooterData.homeReportLink.styles"
            :trigger-function="modalFooterData.homeReportLink.triggerFunction"
          />
        </div>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import ButtonField from '@/components/form/ButtonField.vue';
import RouterSpanField from '@/components/form/RouterSpanField.vue';

export default {
  name: 'ModalPageTransition',

  props: {
    modalSettings: {
      type: Object,
      required: true,
    },
    nextPageFunction: {
      type: Function,
      required: true,
    },
    selectedGenericItems: {
      type: Array,
      required: true,
    },
  },

  components: {
    ButtonField,
    RouterSpanField,
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    currentBuilding() {
      return this.$store.state.buildings.currentBuilding;
    },

    modalHeaderData() {
      return {
        class: this.modalSettings.modal_page_transition
          .header.class,
        style: this.modalSettings.modal_page_transition
          .header.style,
        textSection: {
          class: this.modalSettings.modal_page_transition
            .header.text_section.class,
          style: this.modalSettings.modal_page_transition
            .header.text_section.style,
          text: this.modalSettings.modal_page_transition
            .header.text_section.text,
        },
        closeSection: {
          class: this.modalSettings.modal_page_transition
            .header.close_section.class,
          style: this.modalSettings.modal_page_transition
            .header.close_section.style,
          closeImage: {
            class: this.modalSettings.modal_page_transition
              .header.close_section.close_img.class,
            style: this.modalSettings.modal_page_transition
              .header.close_section.close_img.title,
          },
        },
      };
    },

    modalSectionData() {
      return {
        class: this.modalSettings.modal_page_transition
          .section.class,
        style: this.modalSettings.modal_page_transition
          .section.style,
        header: {
          class: this.modalSettings.modal_page_transition
            .section.header.class,
          style: this.modalSettings.modal_page_transition
            .section.header.style,
          title: {
            class: this.modalSettings.modal_page_transition
              .section.header.title.class,
            style: this.modalSettings.modal_page_transition
              .section.header.title.style,
            text: this.modalSettings.modal_page_transition
              .section.header.title.text,
          },
        },
        section: {
          class: this.modalSettings.modal_page_transition
            .section.content.class,
          style: this.modalSettings.modal_page_transition
            .section.content.style,
          text: this.modalSettings.modal_page_transition
            .section.content.text,
        },
        footer: {
          class: this.modalSettings.modal_page_transition
            .section.footer.class,
          style: this.modalSettings.modal_page_transition
            .section.footer.style,
          advancedModeButton: {
            class: this.modalSettings.modal_page_transition
              .section.footer.advanced_mode_button.class,
            style: this.modalSettings.modal_page_transition
              .section.footer.advanced_mode_button.style,
            text: this.modalSettings.modal_page_transition
              .section.footer.advanced_mode_button.text,
            triggerFunction: this.goToAdvancedMode,
          },
        },
      };
    },

    modalFooterData() {
      return {
        class: this.modalSettings.modal_page_transition
          .footer.class,
        style: this.modalSettings.modal_page_transition
          .footer.style,
        homeReportLink: {
          class:
            this.modalSettings.modal_page_transition
              .footer.link.class,
          style:
            this.modalSettings.modal_page_transition
              .footer.link.style,
          text:
            this.modalSettings.modal_page_transition
              .footer.link.text,
          triggerFunction: this.goToHomeOverviewPage,
        },
      };
    },
  },

  methods: {
    closeModal() {
      return this.$bvModal.hide('modal-page-transition');
    },

    goToHomeOverviewPage() {
      this.$store.dispatch('generic_items/put', this.selectedGenericItems)
        .then(() => {
          this.$router.push({ name: 'home-overview' });
        })
        .catch((error) => { console.log(`error routing - ${error}`); });
    },

    goToAdvancedMode() {
      this.currentBuilding.extra_info.virtual_audit_mode = 'advanced';
      this.currentBuilding.virtual_audit_mode = 'advanced';

      this.$store.dispatch('buildings/update').then(() => {
        this.nextPageFunction();
      }).catch((error) => {
        if (error.response) {
          console.log(error.response.data.errors);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import 'src/assets/stylesheets/components/modal_page_transition';
</style>
