<template>
  <div class="container">
    <h1
      class="d-flex justify-content-center egx-partner-color mb-3"
      v-if="settings.title"
      :class="settings.title.class"
      :style="settings.title.style"
    >
      {{ settings.title.text }}
    </h1>
    <span
      class="d-flex justify-content-center text-center mb-5"
      v-if="settings.description"
      :class="settings.description.class"
      :style="settings.description.style"
      v-html="settings.description.text.replace('#{email}', email)"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '../../router/index';

export default {
  name: 'PasswordInstructions',

  data() {
    return {
      email: '',
    };
  },

  mounted() {
    if (this.$route.query.email && this.settings) {
      this.email = this.$route.query.email.replace(' ', '+');
      this.sendPasswordResetRequest();
    } else {
      router.push({ name: 'home' });
    }
  },

  computed: {
    settings() {
      return this.$store.state.partners.currentPartner
        .settings.sections.update_password_page.password_instructions;
    },
  },

  methods: {
    sendPasswordResetRequest() {
      const params = { email: this.email, isUpdateRequested: true };

      this.$store.dispatch('auth/forgotPassword', params)
        .then(() => {
          console.log('We sent you instructions your password.');
        })
        .catch((error) => {
          const message = error.response.data.errors[0].title
            || 'There was an error with your request.';
          console.log(message);
        });
    },
  },
};
</script>
