<template>
  <div class="text-center">
    <div class="pb-3 p-md-4">
      <h3
        :class="homeAnalysis.sub_sections.roadmap.title.class"
        :style="homeAnalysis.sub_sections.roadmap.title.style"
      >
        {{ homeAnalysis.sub_sections.roadmap.title.text }}
      </h3>
      <p
        class="sub-title home-page-body-text"
        :class="homeAnalysis.sub_sections.roadmap.description.class"
        :style="homeAnalysis.sub_sections.roadmap.description.style"
      >
        {{ homeAnalysis.sub_sections.roadmap.description.text }}
      </p>
    </div>
    <div
      class="container row m-0 consumption-container justify-content-center p-0"
      v-if="hideEnergySaving"
    >
      <div class="col-7 consumption-sub-container m-2">
        <consumption
          :consumption="homeAnalysis.sub_sections.roadmap.sub_sections.money"
          :data="totalMoneySavingsData"
          :moneySaving="true"
        />
      </div>
    </div>
    <div v-if="reportSummary">
      <div
        class="container row m-0 consumption-container justify-content-center p-0"
        v-for="fuel in fuelType"
        :key="fuel.key"
      >
        <div class="col-12 col-sm-5 consumption-sub-container m-2">
          <consumption
            :consumption="homeAnalysis.sub_sections.roadmap.sub_sections.current"
            :data="fuel.val.current"
          />
        </div>
        <div class="arrow-icon d-none d-md-block">
          <img src="../assets/images/icons/arrow_icon.svg">
        </div>
        <div class="col-12 col-sm-5 consumption-sub-container m-2">
          <consumption
            :consumption="homeAnalysis.sub_sections.roadmap.sub_sections.future"
            :data="fuel.val.new"
          />
        </div>
      </div>
    </div>
    <div class="container row m-0 consumption-container justify-content-center p-0" v-else>
      <div class="col-12 col-sm-5 consumption-sub-container m-2">
        <consumption
          :consumption="homeAnalysis.sub_sections.roadmap.sub_sections.current"
          :data="currentVirtualAudit.consumptions.current.total_consumption_before_retrofit"
        />
      </div>
      <div class="arrow-icon d-none d-md-block">
        <img src="../assets/images/icons/arrow_icon.svg">
      </div>
      <div class="col-12 col-sm-5 consumption-sub-container m-2">
        <consumption
          :consumption="homeAnalysis.sub_sections.roadmap.sub_sections.future"
          :data="currentVirtualAudit.consumptions.new.total_consumption_after_retrofit"
        />
      </div>
    </div>
    <building-overview-link :home-analysis="homeAnalysis" class="mt-5" />
  </div>
</template>

<script>
import Consumption from './Consumption.vue';
import BuildingOverviewLink from './BuildingOverviewLink.vue';

export default {
  name: 'Roadmap',

  components: {
    Consumption,
    BuildingOverviewLink,
  },

  props: {
    homeAnalysis: Object,
    currentVirtualAudit: Object,
    hideEnergySaving: Boolean,
    reportSummary: Boolean,
  },

  computed: {
    totalMoneySavingsData() {
      return {
        unit: this.$store.state.partners.currentPartner.currency_icon || '$',
        value:
          Object.values(this.currentVirtualAudit.savings.money)
            .reduce((prev, curr) => prev + curr.value, 0),
      };
    },

    fuelType() {
      const consumptionsByFuel = this.currentVirtualAudit.consumptions_by_fuel;
      const value = Object.entries(consumptionsByFuel)
        .filter(
          ([key, val]) => key !== 'total'
          && (val.current.value !== 0 || val.new.value !== 0),
        )
        .map(([key, val]) => ({ key: key.replace('_', ' '), val }));
      return value;
    },
  },
};
</script>
