<template>
  <div class="detail-display-container">
    <div class="row pt-2">
      <div class="col-6"><slot name="title"></slot></div>
      <div class="col-6 mt-0 d-flex justify-content-end">
        <button type="button"
           class="btn btn-outline-primary btn-sm edit-btn"
           :class="editBtn.class"
           :style="editBtn.style"
           v-if="!applied"
           @click="editClick()"
        >
          {{ editBtn.text }}
        </button>
      </div>
    </div>
    <div><slot name="sub-title"></slot></div>
    <div class="pb-2"><slot name="content"></slot></div>
  </div>
</template>

<script>
export default {
  name: 'ObjDetailDisplayEdit',

  props: {
    editBtnPath: String,
    applied: [Boolean, Object],
    editBtn: Object,
    editSection: String,
  },

  computed: {

  },

  methods: {
    editClick() {
      this.$emit('section-edit', this.editSection);
    },
  },
};
</script>

<style lang="scss">
  @import "../assets/stylesheets/components/obj_detail_display_edit";
</style>
