// eslint-disable-next-line import/no-cycle
import ajax from '../../ajax';

export default {
  namespaced: true,

  state: {
  },

  mutations: {
    // eslint-disable-next-line no-empty-pattern
    updateComment({}, data) {
      // eslint-disable-next-line prefer-destructuring
      const comments = this.state.buildings.currentBuilding.comments;
      const currentComment = comments.find((comment) => comment.id === data.comment.id);

      currentComment.content = data.comment.content;
    },
  },

  actions: {
    localUpdate({ commit }, data) {
      return commit('updateComment', data);
    },

    // eslint-disable-next-line no-empty-pattern
    get({}, params) {
      let url = '/api/v1/comments';

      if (params.projectId) url = `${url}?project_id=${params.projectId}`;

      return ajax.get(url, { params });
    },

    // eslint-disable-next-line no-empty-pattern
    post({}, params) {
      const url = '/api/v1/comments';

      return ajax.post(url, params.comment);
    },

    // eslint-disable-next-line no-empty-pattern
    put({}, params) {
      let url = `/api/v1/comments/${params.comment.id}`;

      if (params.requested_comment_id !== undefined) {
        url = `${url}?requested_comment_id=${params.requested_comment_id}`;
      }

      return ajax.put(url, params.comment);
    },
  },
};
