<template>
  <div id="application-layout" class="application-layout">
    <site-header></site-header>

    <environment-alert />
    <main class="main-content d-flex flex-column align-items-center
        justify-content-center">
      <router-view></router-view>
    </main>
    <site-footer></site-footer>
  </div>
</template>

<script>
import EnvironmentAlert from '@/components/EnvironmentAlert.vue';
import SiteHeader from '../components/headers/SiteHeader.vue';
import SiteFooter from '../components/footers/SiteFooter.vue';

export default {
  name: 'ApplicationLayout',

  components: {
    SiteHeader,
    SiteFooter,
    EnvironmentAlert,
  },

  data() {
    return {

    };
  },
};

</script>

<style>
  @import '../assets/stylesheets/layouts/application_layout.scss';
</style>
