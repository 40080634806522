<template>
  <div class="uploaded-doc-section m-3 ml-5 mr-5 pl-2">
    <p class="text-danger" v-if="uploadError">
      <small>{{ uploadError }}</small>
    </p>
    <div v-if="is_requested" class="pl-3">
      <p class="requested-comment-content text-danger">
        <small>We need you to upload your document/s. Reason:
          <var admin-comment>{{ reasonList(allRequestedComments) }}</var>
        </small>
      </p>
    </div>
    <div class="m-2 pl-3 text-center" v-if="isLoading">
      <font-awesome-icon
        :icon="['fa', 'circle-notch']"
        class="fa-spin m-auto spinner fa-2x"
      />
    </div>
    <template v-else>
      <div class="uploaded-files" v-if="fileList() && fileList().length">
        <div v-for="document in fileList()" :key="'documment-' + document.id">
          <div notranslate>{{ document.attributes.caption }}</div>
          <div class="row file-section mb-1">
            <div class="col-10">
              <a :href="document.attributes.url" target="_blank" class="d-flex">
                <div
                  class="file-name doc-link mt-1"
                  v-html="document.attributes.image_file_name"
                  notranslate
                >
                </div>
              </a>
            </div>
            <div
              class="col-2 d-flex justify-content-end pr-3"
              v-if="!applied || is_requested"
            >
              <span class="x-close" @click="deleteDocument(document)" notranslate>x</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="text-center">
      <b-spinner label="Spinning" class="egx-purple mt-2" v-if="spinnerDisplay" />
    </div>
  </div>
</template>

<script>
import emptyObjectMixin from '../shared/mixins/empty-object';

export default {
  name: 'Document',
  components: {
  },

  props: {
    documentableType: String,
    documentableId: [String, Number],
    applied: [Boolean, Object],
    documentSection: Object,
    currentProject: { type: Object, default: () => ({}) },
    is_requested: Boolean,
  },

  mixins: [emptyObjectMixin],

  created() {
    this.getDocuments();
  },

  data() {
    return {
      errorMesg: '',
      spinnerDisplay: false,
      comment: [],
      allRequestedComments: [],
      uploadError: '',
      isLoading: true,
    };
  },

  computed: {
    documents() {
      return this.$store.state.documents.documents;
    },

    currentBuilding() {
      return this.$store.state.buildings.currentBuilding;
    },
  },

  methods: {
    fileList() {
      const files = this.documents[`${this.documentableType}${this.documentableId}`];

      return files.filter((doc) => doc.attributes.project_id === (this.currentProject?.id || null));
    },

    getDocuments() {
      const self = this;

      const params = {
        documentableType: this.documentableType,
        documentableId: this.documentableId,
        buildingId: this.currentBuilding.id,
      };

      this.$store.dispatch('documents/get', params).then(() => {
        if (this.fileList()) { this.getComment(); }
      }).catch((error) => {
        self.apiErrorMessages(error);
      }).finally(() => {
        this.isLoading = false;
      });
    },

    async createDocument(fileList, reUpload = 'false', documentToDel = null) {
      if (!fileList.length) return;

      const self = this;
      const file = fileList[0];
      this.uploadError = '';

      if (file.size > 31457280) {
        this.uploadError = 'Document size can\'t exceed more than 30MB.';
      } else {
        const formData = new FormData();
        formData.append('documentable_type', self.documentableType);
        formData.append('documentable_id', self.documentableId);
        formData.append('building_id', self.currentBuilding.id);
        formData.append('image', file);
        formData.append('re_upload', reUpload);

        if (!this.emptyObject(self.currentProject)) {
          formData.append('project_id', self.currentProject.id);
        }

        this.spinnerDisplay = true;

        await this.$store.dispatch('documents/post', formData).then(() => {
          if (documentToDel) { self.deleteDocument(documentToDel); }

          if (this.applied) {
            this.$emit('re-upload', this.documentableId, this.currentProject.id);
          }
        }).catch((error) => {
          this.uploadError = 'Issue uploading document. Please check the document and try again.';
          self.apiErrorMessages(error);
        }).finally(() => {
          this.spinnerDisplay = false;
        });
      }
    },

    // TODO: seems like this method is not being used, consider remove it
    updateDocument(document) {
      const self = this;

      this.$store.dispatch('documents/put', document).then(() => {
      }).catch((error) => {
        self.apiErrorMessages(error);
      });
    },

    deleteDocument(document) {
      const self = this;

      const params = {
        id: document.id,
        documentableType: this.documentableType,
        documentableId: this.documentableId,
        buildingId: this.currentBuilding.id,
      };

      this.$store.dispatch('documents/delete', params).then(() => {
      }).catch((error) => {
        self.apiErrorMessages(error);
      });
    },

    apiErrorMessages(error) {
      if (error.response) {
        this.errorMesg = error.response.data.errors;
      } else {
        console.log(error);
      }
    },

    reasonList(reasons) {
      const arr = [];
      reasons.forEach((reason) => {
        arr.push(reason.content);
      });

      return arr.join(', ');
    },

    getComment() {
      if (this.applied) {
        const self = this;

        const params = {
          commentable_type: this.documentableType,
          commentable_id: this.documentableId,
          building_id: this.currentBuilding.id,
          project_id: this.currentProject.id,
          all_owners: true,
        };

        this.$store.dispatch('comments/get', params).then((response) => {
          response.data.data.forEach((comment) => {
            if (comment !== undefined) {
              self.comment.push(comment.attributes);
              let isRequested = false;

              if (comment.attributes.requested_comments.length > 0) {
                self.allRequestedComments.push(comment.attributes.requested_comments[0]);
                isRequested = true;
              }

              this.$emit(
                'request-upload', this.documentableId, isRequested, comment.attributes.project_id,
              );
            }
          });
        });
      }
    },
  },
};
</script>

<style lang="scss">
  @import "../assets/stylesheets/components/document";
</style>
