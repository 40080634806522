<template>
  <div class="not-found container">
    <span
      class="title-404 font-roboto-mono d-flex font-weight-lighter m-auto text-center mb-5 p-4"
      :class="settings.title.class"
      :style="settings.title.style"
    >
      {{ settings.title.text }}
    </span>
    <h1
      class="text-center mt-5"
      :class="settings.sub_title.class"
      :style="settings.sub_title.style"
    >
      {{ settings.sub_title.text }}
    </h1>
    <h5
      class="text-center mt-3"
      :class="settings.description.class"
      :style="settings.description.style"
    >
      {{ settings.description.text }}
    </h5>

    <div class="routes text-center ml-auto mr-auto mt-5">
      <div v-for="route in routes" :key="route.text" class="d-inline-flex m-3 flex-wrap">
        <a
          v-if="route.external_link"
          :class="route.class"
          :style="route.style"
          :href="route.path"
          class="route egx-partner-color"
        >
          <u>{{ route.text }}</u>
        </a>

        <router-link
          v-else
          class="route egx-partner-color"
          :to="route.path"
          :class="route.class"
          :style="route.style"
        >
          <u>{{ route.text }}</u>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  computed: {
    settings() {
      return this.$store.state.partners.currentPartner.settings.sections.not_found.not_found;
    },

    routes() {
      return this.settings.sub_sections;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/components/not-found.scss';
</style>
