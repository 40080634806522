<template>
  <div v-if="show_modal" class="fixed_modal">
    <div class="modal_content">
      <p>{{ message }}</p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show_modal: Boolean,
    message: String,
  },
};
</script>

<style lang="scss">
.fixed_modal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(79, 76, 76, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal_content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
  }
}
</style>
