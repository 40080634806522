<script>
import { HorizontalBar } from 'vue-chartjs';
import chartLabelManager from '@/shared/mixins/chartLabelManager';

export default {
  extends: HorizontalBar,

  props: {
    currentChart: Object,
    barStyle: String,
    currentUnit: String,
    currencyIcon: String,
  },

  beforeMount() {
    this.initializeLabels();
  },

  mounted() {
    this.renderBarChart(this.chartData, this.options);
  },

  watch: {
    chartLabels() {
      this.renderBarChart(this.chartData, this.options);
    },

    barStyle() {
      this.renderBarChart(this.chartData, this.options);
    },
  },

  mixins: [chartLabelManager],

  data() {
    const vm = this;
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            formatter(value) {
              if (vm.currentUnit === '$') {
                return `${vm.currencyIcon} ${value.toFixed(2)}`;
              }
              return `${value.toFixed(2)} ${vm.currentUnit}`;
            },
            font: {
              weight: 'bold',
            },
          },
        },
        layout: {
          padding: {
            right: 35,
            left: 20,
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              autoSkip: false,
              fontSize: 12,
              beginAtZero: true,
              scroll: true,
            },
            gridLines: {
              display: false,
            },
          }],
          xAxes: [{
            display: false,
          }],
        },
        tooltips: {
          callbacks: {
            label(tooltipItem) {
              if (vm.currentUnit === '$') {
                return `$${tooltipItem.xLabel}`;
              }
              return `${tooltipItem.xLabel}kg`;
            },
          },
        },
      },
      chartLabels: {},
    };
  },

  computed: {
    chartData() {
      return {
        labels: this.chartLabels.dataLabels,
        datasets: [
          {
            barThickness: 25,
            backgroundColor: `${this.barStyle}`,
            data: this.data,
          },
        ],
      };
    },

    dataKeys() {
      return Object.keys(this.currentChart);
    },

    labels() {
      const labels = [];
      this.dataKeys.forEach((data) => {
        const addedLabel = this.currentChart[data].label;

        if (!this.currentChart[data].hideInAnalysis) { labels.push(addedLabel); }
      });

      return labels;
    },

    data() {
      const value = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.dataKeys.length; i++) {
        if (!this.currentChart[this.dataKeys[i]].hideInAnalysis) {
          value.push(this.currentChart[this.dataKeys[i]].value);
        }
      }
      return value;
    },
  },

  methods: {
    renderBarChart(dataset, options) {
      this.renderChart(dataset, options);
    },

    initializeLabels() {
      const initialLabels = {
        dataLabels: this.labels,
      };

      this.chartLabels = initialLabels;

      this.getLabels(initialLabels).then((newLabels) => {
        this.chartLabels = newLabels;
      });
    },
  },
};
</script>
