<template>
  <div class="w-100">
    <div class="w-100 text-center mt-4" v-html="pageSection.header.content.title" />
    <div class="w-100 text-center mt-4" v-if="pageSection.header.content.subtitle"
      v-html="pageSection.header.content.subtitle" />
    <div class="mt-4" v-html="pageSection.header.content.description" />

    <div class="w-100 text-center mt-4" v-html="pageSection.content.content.title" />
    <div class="w-100 text-center mt-4" v-if="pageSection.content.content.subtitle"
      v-html="pageSection.content.content.subtitle" />
    <div class=" mt-4" v-html="pageSection.content.content.description" />
  </div>
</template>

<script>

export default {  
  name: 'PageSectionWithoutForm',

  props: {
    pageSection: Object,
  },
  methods: {
    handleSubmit() {
      this.$emit('resend');
    },
  },
};
</script>
