<template>
  <b-container class="thank-you">
    <div class="content-box mt-5" :style="customCss">
      <span
        class="mt-5 d-flex justify-content-center egx-partner-color title"
        :class="this.settings.thank_you_section.title.class"
        :style="this.settings.thank_you_section.title.style"
      >
        {{ this.settings.thank_you_section.title.text }}
      </span>

      <div class="main-content text-center mt-5 content ml-4 mr-4">
      <span class="font-weight-light">
        {{ this.settings.thank_you_section.content.text }}
      </span>
        <router-link
          :tag="'span'"
          :to="{ name: this.settings.thank_you_section.cta.page_name }"
          class="egx-partner-color cta"
        >
          {{ this.settings.thank_you_section.cta.text }}
        </router-link>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'ThankYou',

  components: {
  },

  data() {
    return {
      type: 'renovation',
      data: {
        thankYou: {
        },
      },
    };
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    settings() {
      return this.partner.settings.sections.thank_you;
    },

    customCss() {
      return this.$store.state.partners.currentPartner.settings.sections.custom_css;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../assets/stylesheets/components/thank_you";
</style>
