<template>
  <div class="container how-it-works text-center mt-5">
    <h1
      class="pb-2"
      :class="settings.main_title.class"
      :style="settings.main_title.style"
      v-if="settings.main_title"
    >
      {{ settings.main_title.text }}
    </h1>
    <h1
      :class="settings.title.class"
      :style="settings.title.style"
    >
      {{ settings.title.text }}
    </h1>
    <h5
      class="mt-4 mb-5 egx-partner-color"
      :class="settings.description.class"
      :style="settings.description.style"
    >
      {{ settings.description.text }}
    </h5>

    <div v-for="(section, index) in settings.sub_sections" :key="`icon-${index}`">
      <div class="mt-5">
        <div
          class="icon-container d-flex m-auto mt-5"
          :class="section.class"
          :style="section.style"
        >
          <font-awesome-icon
            :icon="section.icon.type"
            class="m-auto icon"
            :class="section.icon.class"
            :style="section.icon.style"
          />
        </div>
        <div
          class="icon-line ml-auto mr-auto"
          :class="section.class"
          :style="section.style"
        />
      </div>
      <h3
        class="mt-4"
        :class="section.title.class"
        :style="section.title.style"
      >
       {{ section.title.text }}
      </h3>
      <span
        class="mt-3"
        :class="section.description.class"
        :style="section.description.style"
      >
        {{ section.description.text }}
      </span>
    </div>

    <div class="btn-lg row justify-content-center">
      <router-link
        tag="b-button"
        class="btn mt-1 mt-md-3 ml-2 mr-2 btn-egx-white col-12 col-md-3"
        :class="settings.cta.class"
        :style="settings.cta.style"
        :to="settings.cta.path"
      >
        {{ settings.cta.text }}
      </router-link>

      <router-link
        v-if="settings.cta2"
        tag="b-button"
        class="btn mt-1 mt-3 ml-2 mr-2 btn-egx col-12 col-md-3"
        :class="settings.cta2.class"
        :style="settings.cta2.style"
        :to="settings.cta2.path"
      >
        {{ settings.cta2.text }}
      </router-link>
    </div>

    <div
      v-if="settings.footer"
      class="mt-3"
      :class="settings.footer.class"
      :style="settings.footer.style"
    >
      {{ settings.footer.text }}
    </div>

  </div>
</template>

<script>
export default {
  computed: {
    settings() {
      return this.$store.state.partners.currentPartner.settings.sections.how_it_works.how_it_works;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/components/how_it_works.scss';
</style>
