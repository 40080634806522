import Vue from 'vue';
// eslint-disable-next-line import/no-cycle
import ajax from '../../ajax';

export default {
  namespaced: true,

  state: {
    documents: {},
  },

  getters: {
    checkDocument: (state) => (requiredDocuments, currentProjectIds) => {
      if (requiredDocuments.length === 0) return true;

      let documentCheck = false;

      // eslint-disable-next-line no-restricted-syntax
      for (const requiredDocument of requiredDocuments) {
        const listItem = `${requiredDocument.required_type}${requiredDocument.required_id}`;
        const docs = state.documents[listItem];
        const uploadedProjectIds = [...new Set(docs.map((item) => item.attributes.project_id))];

        if (currentProjectIds.includes(null)) {
          documentCheck = uploadedProjectIds.includes(null);
        } else {
          const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b);
          documentCheck = equals(uploadedProjectIds.sort(), currentProjectIds.sort());
        }
      }

      return documentCheck;
    },
  },

  mutations: {
    set(state, data) {
      Vue.set(state.documents, data.documentableType + data.documentableId, data.documents);
    },

    create(state, data) {
      state.documents[data.documentableType + data.documentableId].push(data.documents);
    },

    put(state, data) {
      const index = state.documents[data.documentableType + data.documentableId]
        .findIndex((doc) => doc.id === data.document.id);

      if (index !== -1) {
        state.documents[data.documentableType + data.documentableId]
          .splice(index, 1, data.document);
      }
    },

    delete(state, data) {
      const index = state.documents[data.documentableType + data.documentableId]
        .findIndex((doc) => doc.id === data.id);

      if (index !== -1) {
        state.documents[data.documentableType + data.documentableId].splice(index, 1);
      }
    },
  },

  actions: {
    get({ commit }, params) {
      let url = `/api/v1/documents?documentable_type=${params.documentableType}&documentable_id=${params.documentableId}&building_id=${params.buildingId}`;

      if (params.projectId) url = `${url}&project_id=${params.projectId}`;

      return ajax.get(url)
        .then((response) => {
          commit('set', {
            documentableType: params.documentableType,
            documentableId: params.documentableId,
            documents: response.data.data,
          });
        });
    },

    post({ commit }, document) {
      const url = '/api/v1/documents';

      return ajax.post(url, document, {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      }).then((response) => commit('create', {
        documentableType: response.data.data.attributes.documentable_type,
        documentableId: response.data.data.attributes.documentable_id,
        documents: response.data.data,
      }));
    },

    put({ commit }, document) {
      const url = `/api/v1/documents/${document.id}`;

      return ajax.put(url, document.attributes)
        .then((response) => commit('put', {
          documentableType: response.data.data.attributes.documentable_type,
          documentableId: response.data.data.attributes.documentable_id,
          document: response.data.data,
        }));
    },

    delete({ commit }, params) {
      const url = `/api/v1/documents/${params.id}?documentable_type=${params.documentableType}&documentable_id=${params.documentableId}&building_id=${params.buildingId}`;

      return ajax.delete(url)
        .then(() => commit('delete', {
          documentableType: params.documentableType,
          documentableId: params.documentableId,
          id: params.id,
        }));
    },
  },
};
