import Vue from 'vue';
import VueRouter from 'vue-router';
import titleize from '../shared/filters/titleize';
// eslint-disable-next-line import/no-cycle
import store from '../store/index';

import Home from '../views/Home.vue';
import About from '../views/About.vue';
import HowItWorks from '../views/HowItWorks.vue';
import SignIn from '../views/auth/SignIn.vue';
import AdminUserSignUp from '../views/admin/AdminUserSignUp.vue';
import BuildingDetails from '../views/BuildingDetails.vue';
import BuildingsList from '../views/BuildingsList.vue';
import LoginAs from '../views/auth/loginAs.vue';
import FuelType from '../views/FuelType.vue';
import HeatingSystem from '../views/HeatingSystem.vue';
import HouseType from '../views/HouseType.vue';
import AreaBasics from '../views/AreaBasics.vue';
import Renovation from '../views/Renovation.vue';
import Insulation from '../views/Insulation.vue';
import WindowsAndDoors from '../views/WindowsAndDoors.vue';
import VentilationSystem from '../views/VentilationSystem.vue';
import HomeOverview from '../views/HomeOverview.vue';
import HomeAnalysis from '../views/HomeAnalysis.vue';
import Incentives from '../views/Incentives.vue';
import UserEdit from '../views/user/UserEdit.vue';
import ThankYou from '../views/ThankYou.vue';
import ForgotPassword from '../views/auth/ForgotPassword.vue';
import UpdatePassword from '../views/auth/UpdatePassword.vue';
import RequestedUpdatePassword from '../views/auth/RequestedUpdatePassword.vue';
import CreatePassword from '../views/auth/CreatePassword.vue';
import NotFound from '../views/NotFound.vue';
import Welcome from '../views/Welcome.vue';
import TermsAndConditions from '../views/TermsAndConditions.vue';
import Maintenance from '../views/Maintenance.vue';
import WaterHeatingSystem from '../views/WaterHeatingSystem.vue';
import PasswordInstructions from '../views/auth/PasswordInstructions.vue';
import SchedulePlan from '../views/SchedulePlan.vue';
import SignUpCentral from '../views/auth/SignUpCentral.vue';
import VerifyEmail from '../views/auth/VerifyEmail.vue';
import EmailVerification from '../views/auth/EmailVerification.vue';
import ResendEmailVerification from '../views/auth/ResendEmailVerification.vue';

Vue.use(VueRouter);

function checkForAssessmentEnabled(to, from, next) {
  if (!store.state.partners.currentPartner.settings.enable_assessment) {
    console.info('Page cannot be accessed! Assessment is not enabled.');
    return next({ name: 'buildings-list' });
  }

  return next();
}

function sessionExpireRedirectPath() {
  return store.state.partners.currentPartner.sso.session_expire_redirect_path;
}

function userLogInRequired(to, from, next) {
  const { currentUser } = store.state.users;
  let nextFlow = next();

  if (Object.keys(currentUser).length === 0) {
    let query = { redirect: '' };
    const redirectURI = encodeURI(to.fullPath);
    if (to.fullPath.includes('sign-in')) {
      query = {};
    } else {
      query.redirect = redirectURI;
    }

    nextFlow = next({ name: (sessionExpireRedirectPath() || 'sign-in') });
  } else if (currentUser.name === 'New user' && to.name !== 'UserEdit') {
    nextFlow = next({ name: 'UserEdit' });
  } else if (currentUser.extra_info
    && currentUser.extra_info.user_csv_import_building_id) {
    nextFlow = next({ path: `/buildings/${currentUser.extra_info.user_csv_import_building_id}` });
  }

  return nextFlow;
}

function inMaintenanceMode() {
  return store.state.partners.currentPartner.maintenance_mode;
}

function bypassMaintenanceMode() {
  if (!store.state.users.currentUser) return false;

  const testerEmails = store.state.partners.currentPartner.settings.tester_emails
    .split(',')
    .filter((email) => email !== '')
    .map((email) => email.trim());

  return testerEmails.includes(store.state.users.currentUser.email);
}

function hideUserPassLogin() {
  return store.state.partners.currentPartner.sso.hide_user_pass_login;
}

function allowAdminSignUp() {
  return store.state.partners.currentPartner.settings.admin_signup_allowed;
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/how-it-works',
    name: 'how-it-works',
    component: HowItWorks,
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: SignUpCentral,
    beforeEnter: (to, from, next) => {
      if (hideUserPassLogin()) {
        return next({ name: 'sign-in' });
      }

      return next();
    },
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: SignIn,
  },
  {
    path: '/admin-user-sign-up/:admin_id',
    name: 'AdminUserSignUp',
    component: AdminUserSignUp,
    beforeEnter: (to, from, next) => {
      if (!allowAdminSignUp()) {
        return next({ name: 'sign-in' });
      }

      return next();
    },
  },
  {
    path: '/buildings-new',
    name: 'building-details',
    component: BuildingDetails,
    beforeEnter: (to, from, next) => {
      userLogInRequired(to, from, next);
    },
  },
  {
    path: '/buildings/:id',
    name: 'building-details',
    component: BuildingDetails,
    meta: {
      title: 'Building Details',
    },
    beforeEnter: (to, from, next) => {
      userLogInRequired(to, from, next);
    },
  },
  {
    path: '/buildings',
    name: 'buildings-list',
    component: BuildingsList,
    beforeEnter: (to, from, next) => {
      userLogInRequired(to, from, next);
    },
  },
  {
    path: '/user-schedule-plans/:id',
    name: 'user-schedule-plans',
    component: SchedulePlan,
    beforeEnter: (to, from, next) => {
      userLogInRequired(to, from, next);
    },
  },
  {
    path: '/login-as',
    name: 'LoginAs',
    component: LoginAs,
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: VerifyEmail,
  },
  {
    path: '/email-verification',
    name: 'EmailVerification',
    component: EmailVerification,
  },
  {
    path: '/resend-email-verification',
    name: 'ResendEmailVerification',
    component: ResendEmailVerification,
  },
  {
    path: '/fuel-type',
    name: 'fuel-type',
    component: FuelType,
    beforeEnter: (to, from, next) => {
      checkForAssessmentEnabled(to, from, next);
    },
  },
  {
    path: '/heating-system',
    name: 'heating-system',
    component: HeatingSystem,
    beforeEnter: (to, from, next) => {
      checkForAssessmentEnabled(to, from, next);
    },
  },
  {
    path: '/water-heating-system',
    name: 'water-heating-system',
    component: WaterHeatingSystem,
    beforeEnter: (to, from, next) => {
      checkForAssessmentEnabled(to, from, next);
    },
  },
  {
    path: '/house-type',
    name: 'house-type',
    component: HouseType,
    beforeEnter: (to, from, next) => {
      checkForAssessmentEnabled(to, from, next);
    },
  },
  {
    path: '/area-basics',
    name: 'area-basics',
    component: AreaBasics,
    beforeEnter: (to, from, next) => {
      checkForAssessmentEnabled(to, from, next);
    },
  },
  {
    path: '/renovation',
    name: 'renovation',
    component: Renovation,
    beforeEnter: (to, from, next) => {
      checkForAssessmentEnabled(to, from, next);
    },
  },
  {
    path: '/insulation',
    name: 'insulation',
    component: Insulation,
    beforeEnter: (to, from, next) => {
      if (store.state.buildings.currentBuilding.extra_info.virtual_audit_mode !== 'advanced') {
        console.info('Page cannot be accessed! Current building is not in advanced mode.');
        return next({ name: 'buildings-list' });
      }

      return next();
    },
  },
  {
    path: '/windows-and-doors',
    name: 'windows-and-doors',
    component: WindowsAndDoors,
    beforeEnter: (to, from, next) => {
      if (store.state.buildings.currentBuilding.extra_info.virtual_audit_mode !== 'advanced') {
        console.info('Page cannot be accessed! Current building is not in advanced mode.');
        return next({ name: 'buildings-list' });
      }

      return next();
    },
  },
  {
    path: '/ventilation-system',
    name: 'ventilation-system',
    component: VentilationSystem,
    beforeEnter: (to, from, next) => {
      checkForAssessmentEnabled(to, from, next);
    },
  },
  {
    path: '/home-overview',
    name: 'home-overview',
    component: HomeOverview,
    beforeEnter: (to, from, next) => {
      checkForAssessmentEnabled(to, from, next);
      userLogInRequired(to, from, next);
    },
  },
  {
    path: '/home-analysis',
    name: 'home-analysis',
    component: HomeAnalysis,
    beforeEnter: (to, from, next) => {
      checkForAssessmentEnabled(to, from, next);
    },
  },
  {
    path: '/incentives',
    name: 'incentives',
    component: Incentives,
    beforeEnter: (to, from, next) => {
      if (!store.state.partners.currentPartner.settings.enable_incentives) {
        console.info('Page cannot be accessed! Incentives are not enabled.');
        return next({ name: 'buildings-list' });
      }

      return next();
    },
  },
  {
    path: '/user/edit',
    name: 'UserEdit',
    component: UserEdit,
    beforeEnter: (to, from, next) => {
      userLogInRequired(to, from, next);
    },
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    component: ThankYou,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/request-password',
    name: 'request-password',
    component: ForgotPassword,
  },
  {
    path: '/update-password',
    name: 'update-password',
    component: UpdatePassword,
  },
  {
    path: '/welcome',
    name: 'create-password',
    component: CreatePassword,
  },
  {
    path: '/requested-update-password',
    name: 'requested-update-password',
    component: RequestedUpdatePassword,
  },
  {
    path: '/password-instructions',
    name: 'password-instructions',
    component: PasswordInstructions,
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
    meta: {
      title: 'Terms and Conditions',
    },
  },
  {
    path: '/hello',
    name: 'Welcome',
    component: Welcome,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
    meta: {
      title: 'Error 404 (Not Found)!!',
    },
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
  },
  {
    path: '/saml-logout',
    name: 'saml-logout',
    beforeEnter: (to, from, next) => {
      const { sso } = store.state.partners.currentPartner;

      if (sso.sp_initiated_slo) {
        window.location.href = sso.logout_url;
      } else if (sso.spa_home_url) {
        window.location.href = sso.spa_home_url;
      } else {
        next({ name: 'home' });
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.afterEach((to) => {
  document.title = to.meta.title || titleize(to.path);
});

router.beforeEach((to, from, next) => {
  if (inMaintenanceMode() && !['Maintenance', 'LoginAs'].includes(to.name) && !bypassMaintenanceMode()) next({ name: 'Maintenance' });
  else next();
});

export default router;
