<template>
  <div class="container user-edit mt-5">
    <div class="mb-5 ">
      <h1
        class="text-center"
        :class="settings.attributes.title.class"
        :style="settings.attributes.title.style"
      >
        {{ settings.attributes.title.text }}
      </h1>
      <h4
        class="text-center"
        :class="settings.attributes.subtitle.class"
        :style="settings.attributes.subtitle.style"
      >
        {{ settings.attributes.subtitle.text }}
      </h4>
    </div>
    <user-form-fields
      @update-success="redirectToBuildingLists()"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '../../router/index';
import UserFormFields from '../../components/UserFormFields.vue';

export default {
  name: 'UserEdit',

  components: {
    UserFormFields,
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    settings() {
      return this.partner.settings.sections.user_edit_page;
    },
  },

  methods: {
    redirectToBuildingLists() {
      this.$store.dispatch('buildings/setNew').then(() => {
        router.push({ name: 'buildings-list' });
      });
    },
  },
};
</script>
