import numeral from 'numeral';

// Methods dealing with numbers
export default {
  methods: {
    wholeNumber(value) {
      return numeral(value).format('0');
    },
  },
};
