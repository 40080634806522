// eslint-disable-next-line import/no-cycle
import ajax from '../../ajax';

export default {
  namespaced: true,

  state: {
    availableEa: [],
  },

  mutations: {
    set() {
    },

    setAvailableEa(state, data) {
      state.availableEa = data;
    },
  },

  actions: {
    create({ commit }, params) {
      commit('set');
      return ajax.post('/api/v1/energy_advisor_assignments', params);
    },

    get({ commit }, projectTypeId) {
      const url = `/api/v1/energy_advisor_assignments/available_energy_advisors?project_type_id=${projectTypeId}`;
      return ajax.get(url)
        .then((response) => {
          commit('setAvailableEa', response.data.data);
        });
    },
  },
};
