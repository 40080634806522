<template>
  <loading-screen v-show="isVisible" :content="loadingScreenContent" />
</template>

<script>
import Vue from 'vue';
import router from '../../router/index';
import LoadingScreen from '../../components/LoadingScreen.vue';

export default {
  name: 'ResentEmailVerification',

  components: {
    LoadingScreen,
  },

  data() {
    return {
      loadingScreenContent: {
        title: {
          text: 'Resending Verification Email',
          class: [],
        },
        description: {
          text: 'Please wait while we are resending the verification email',
          class: [],
          style: {},
        },
        spinner: {
          class: ['egx-partner-color'],
          style: {},
        },
        cta: {
          text: 'If this page doesn\'t refresh, please click here',
          class: ['egx-partner-color'],
          style: {},
          path_name: 'EmailVerification',
        },
      },
      isVisible: false,
    };
  },

  beforeRouteEnter(to, from, next) {
    Vue.loginAsToken = to.query.token;

    next();
  },

  mounted() {
    this.beginLoginAs();
  },

  computed: {
    isSSOSignIn() {
      return this.$store.state.users.currentUser.third_party_signup;
    },

    sso() {
      return this.$store.state.partners.currentPartner.sso;
    },

    ssoLandingPage() {
      return this.sso.login_landing_page;
    },
  },

  methods: {
    beginLoginAs() {
      this.isVisible = true;
      const token = this.$route.query.token;
      const promise = new Promise((resolve) => {
        resolve(this.$store.dispatch('auth/resendEmailVerification', { token }));
      }).then((response) => {
        this.isVisible = false;
        this.$notify({
          group: 'notice',
          text: 'Verification email resent successfully',
          duration: 5000,
        });
        router.push({ name: 'EmailVerification', query: { token: response.confirmation_token } });
      });

      Promise.all([promise])
        .catch((error) => {
          console.log(error);
          this.isVisible = false;
            this.$notify({
            group: 'notice',
            text: error.response.data.error,
            duration: 5000,
            });
          router.push({ name: 'EmailVerification', query: { token } });
        });
    },
  },
};
</script>
