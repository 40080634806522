<template>
  <div class="savings d-flex flex-wrap flex-md-nowrap">
    <div class="money-savings mb-2 mb-sm-0">
      <img
        src="../assets/images/icons/savings_money.svg"
        class="money-savings-icon"
      />
      <span class="money-description">
        Save
        <var incentive-savings-value>
          {{ currencyIcon }}
          {{ incentiveSavings.money }}
        </var> {{ savingsText.money }}
      </span>
    </div>

    <div class="energy-savings mb-2 mb-sm-0">
      <img
        src="../assets/images/icons/savings_energy.svg"
        class="ghg-savings-icon"
      />
      <span class="energy-description">
        Save <var incentive-savings-ghg>{{ incentiveSavings.ghg }}</var> {{ savingsText.ghg }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Incentives',

  props: {
    incentiveSavings: Object,
    savingsText: Object,
    currencyIcon: String,
  },
};
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/pages/incentive_savings';
</style>
