<template>
  <div>
    <p
      class="p-5 small-title thicker-font-weight"
      :class="consumption.title.class"
      :style="consumption.title.style"
    >
      {{ consumptionText.title }}
    </p>
    <h3
      class="consumption-value m-auto"
      :class="consumption.number.class"
      :style="consumption.number.style"
    >
      <var consumption-value>{{ consumptionValue }}</var>
      <sup
        class="consumption-sup"
        v-if="consumption.title.tooltip"
        :id="`${consumption.title.text}-consumption-tooltip`"
      >
        <font-awesome-icon :icon="['fa', 'question']" class="consumption-tooltip" />
      </sup>
    </h3>
    <b-tooltip
      :target="`${consumption.title.text}-consumption-tooltip`"
      custom-class="partner-tooltip"
      triggers="hover"
    >
      {{ consumption.title.tooltip }}
    </b-tooltip>
    <p
      class="description p-5"
      :class="consumption.description.class"
      :style="consumption.description.style"
    >
      {{ consumptionText.description }}
    </p>
  </div>
</template>

<script>
import Numeric from '../shared/mixins/numeric';

export default {
  name: 'consumption',

  mixins: [Numeric],

  props: {
    consumption: Object,
    data: Object,
    moneySaving: Boolean,
  },

  computed: {
    currentBuilding() {
      return this.$store.state.buildings.currentBuilding;
    },

    consumptionValue() {
      let value = this.wholeNumber(this.data.value);

      if (this.moneySaving) {
        value = `${this.data.unit}${value}`;
      } else {
        value = `${value} ${this.consumption.title.unit || this.data.unit}`;
      }

      return `${value} per year `;
    },
    consumptionText() {
      return {
        title: this.data.title || this.consumption.title.text,
        description: this.data.description || this.consumption.description.text,
      };
    },
  },
};
</script>

<style>
  @import '../assets/stylesheets/pages/home_analysis.scss';
  @import '../assets/stylesheets/partials/_general.scss';
</style>
