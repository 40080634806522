/* eslint-disable camelcase  */
import { extend } from 'vee-validate';
import {
  min_value,
  max_value,
  required,
  min,
  confirmed,
  numeric,
} from 'vee-validate/dist/rules';

extend('min_value', {
  ...min_value,
  message: 'This field must be at least {min}',
});

extend('max_value', {
  ...max_value,
  message: 'This field must be at less than {max}',
});

extend('required', {
  ...required,
  message: 'This field is required.',
});

extend('confirmed', {
  ...confirmed,
  message: 'Passwords do not match. Re-enter password.',
});

extend('min', {
  ...min,
  message: 'This field must be at least {length} characters',
});

extend('numeric', {
  ...numeric, // copies rule configuration,
  message: 'This field must contain numeric values without commas or other signs.',
});

extend('decimal', {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return { valid: false };
    }

    if (Number(decimals) === 0) { return { valid: /^-?\d*$/.test(value) }; }

    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    return { valid: regex.test(value) };
  },
  message: 'This field must contain decimal values without commas or other signs.',
});
