export default function (str, firstWordOnly = false) {
  if (!str) return '';

  if (firstWordOnly) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}
