<template>
  <div class="schedule_container col-10 p-4">
    <loading-screen v-if="detailsLoading" :content="this.loadingContent" />
    <div class="content-box row col-md-12 col-lg-10" v-else>
      <div class="col-12">
        <header>
          <h2 class="mt-3 egx-partner-color">
            {{ pageSettings.page_title }}
          </h2>
          <span>
            {{ pageSettings.page_description }}
          </span>

          <h4 class="mt-4">
            {{ pageSettings.application_title }}
          </h4>
          <span>
            {{ currentProject.project_type.name }}
          </span>
        </header>
      </div>
      <br />
      <div class="schedule_section">
        <div class="schedule_section_box">
          <h4>
            Select an Energy Advisor
          </h4>
          <select class="available_ea" v-model="energyAdvisorId" @change="filteredTimelost">
            <option :value="''">Available Energy Advisors</option>
            <option
              v-for="item in available_ea"
              :value="item.energy_advisor_admin ? item.energy_advisor_admin.admin_id : item.id"
              :key="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="calendar_section_box">
          <FixedModal
            :show_modal="show_modal"
            :message="'Select an Energy Advisor to unlock their calendar'"
          />
          <FixedModal
            :show_modal="!show_modal && schedulePlanTimeslots.length <= 0"
            :message="'No time slots available for this Energy Advisor, please select another one'"
          />
          <FixedModal
            :show_modal="!show_modal && schedulePlanTimeslots.length <= 0"
            :message="'No time slots available for this Energy Advisor, please select another one'"
          />
          <loading-screen v-if="this.loading" :content="this.loadingContent" />
          <template v-else>
            <h4>Calendar</h4>
            <Calendar
              class="mt-3"
              :propsEvents="schedulePlanTimeslots"
              @submit="$event => assisgnTimeslot($event)"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from '../components/Calendar.vue';
import FixedModal from '../components/modals/FixedModal.vue';
import LoadingScreen from '../components/LoadingScreen.vue';

export default {
  name: 'SchedulePlan',
  components: {
    Calendar,
    FixedModal,
    LoadingScreen,
  },
  data() {
    return {
      dateChanged: {
        selectedDate: null,
        dateClick: false,
      },
      energyAdvisorId: '',
      schedulePlanTimeslots: [],
      loading: false,
    };
  },

  computed: {
    schedulePlans() {
      return this.$store.state.schedulePlans.schedulePlans;
    },
    available_ea() {
      const availableAdvisors = this.$store.state.buildings.currentBuilding
        .available_energy_advisors_with_admins;

      if (!availableAdvisors || !Array.isArray(availableAdvisors)) {
        return [];
      }

      const filteredAdvisors = availableAdvisors.filter((item) => item !== null);

      return filteredAdvisors;
    },
    show_modal() {
      return !this.energyAdvisorId;
    },
    pageSettings() {
      return this.$store.state.partners.currentPartner.settings.sections
        .user_schedule_plan_page
        .user_schedule_plan_page;
    },
    currentProject() {
      const projects = this.$store.state.buildings.currentBuilding.rebate_project;
      const childProjects = projects
        .flatMap((project) => project.child_projects || []);
      const currentProject = childProjects
        .find((child) => child && child.id === Number(this.$route.params.id));
      return currentProject;
    },

    loadingContent() {
      return {
        title: {
          text: 'Loading Energy Advisor Calendar',
          style: {
            color: '#512A44',
          },
          class: [
            'font-montserrat',
          ],
        },
        description: {
          text: 'We’ll redirect you automatically.',
          style: {
            color: '#000000',
            'font-family': 'Nunito',
          },
        },
        spinner: {
          class: [
            'egx-partner-color',
          ],
        },
        cta: {
          text: 'Page not refreshing? Go to the incentives page.',
          style: {
            color: '#0097A9',
            'font-family': 'Nunito',
          },
          path_name: 'incentives',
        },
      };
    },

    detailsLoading() {
      return !(this.available_ea && this.currentProject);
    },
  },

  methods: {
    extractStartAndEndDates(data) {
      return data.map((item) => (
        {
          start: item.attributes.start_at.split('T')[0],
          end: item.attributes.finish_at.split('T')[0],
        }
      ));
    },

    assisgnTimeslot(params) {
      const {
        _context, _def, _instance, ...updatePlanTimeslot
      } = params.schedulePlanTimeslot;
      const payload = {
        project_id: this.$route.params.id,
        schedule_plan_timeslot_id: params.schedulePlanTimeslotId,
        schedule_plan_timeslot: { ...updatePlanTimeslot, title: updatePlanTimeslot.originalTitle },
      };
      const self = this;
      this.$store.dispatch('schedulePlanTimeslots/assignTimeslot', payload)
        .then(() => {
          this.$notify({
            group: 'notice',
            text: 'Application scheduled.',
          });
          this.$store.dispatch('schedulePlanTimeslots/get').then(() => {
            this.filteredTimelost();
          });
          this.consent = false;
          this.$router.push({ name: 'incentives' });
        })
        .catch((error) => {
          if (error.response && Object.keys(error.response.data)[0] === 'data') {
            self.errorMsg = error.response.data.data.attributes;
          } else if (error.response && Object.keys(error.response.data)[0] === 'errors') {
            self.errorMsg = error.response.data.errors;
          }

          this.$notify({
            group: 'notice',
            text: self.errorMsg,
          });
        });
    },

    filteredTimelost() {
      this.loading = true;
      const { timeslots } = this.$store.state.schedulePlanTimeslots;
      const currentDate = new Date();
      this.schedulePlanTimeslots = timeslots
        .filter((timeslot) => (
          timeslot.admin_id === this.energyAdvisorId
          && new Date(timeslot.start_at) >= currentDate
        ))
        .map((slot) => {
          const matchingEA = this.available_ea
            .find((ea) => ea.energy_advisor_admin
              && ea.energy_advisor_admin.admin_id === slot.admin_id);
          const eaName = matchingEA ? matchingEA.name : 'Unknown EA';
          return {
            ...slot,
            title: slot.status,
            originalTitle: slot.title,
            energyAdvisorName: eaName,
            project_name: this.currentProject.project_type.name,
          };
        });

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/stylesheets/pages/schedule_plan';
</style>
