// eslint-disable-next-line import/no-cycle
import timeslotObjectSettings from '@/shared/mixins/schedulePlanTimeslot';
// eslint-disable-next-line import/no-cycle
import ajax from '../../ajax';

export default {
  namespaced: true,

  state: {
    timeslots: {},
  },

  mutations: {
    set(state, data) {
      state.timeslots = data;
    },
  },
  mixins: [timeslotObjectSettings],

  actions: {
    get({ getters, commit }) {
      return ajax.get('/api/v1/users_schedule_plan_timeslots').then(async (response) => {
        const timeslotsWithStartEnd = await timeslotObjectSettings.methods
          .timeslotObject(getters, response.data.data);
        commit('set', timeslotsWithStartEnd);
      });
    },

    async assignTimeslot({ commit }, params) {
      return ajax.post('/api/v1/assignment_scheduleplan_timeslot', params)
        .then((response) => {
          console.log(response);
          console.log(commit);
        })
        .catch((error) => Promise.reject(error));
    },
  },

  getters: {
    timeslotSettings: (state, getters, rootState) => rootState
      .partners.currentPartner.settings.schedule_plan_timeslots_user,
  },
};
