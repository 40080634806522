import router from '../../router';

export default {
  data() {
    return {
      routeName: this.$router.currentRoute.name,
    };
  },

  computed: {
    currentBuilding() {
      return this.$store.state.buildings.currentBuilding;
    },

    partner() {
      return this.$store.state.partners.currentPartner;
    },

    showHeatingSystem() {
      return this.partner.settings.sections.audit_sections.heating_system.heating_system
        .configurations.enable;
    },

    showWaterHeatingSystem() {
      return this.partner.settings.sections.audit_sections.water_heating_system.water_heating_system
        .configurations.enable;
    },
  },

  methods: {
    back(prevPage = null) {
      if (prevPage) {
        router.push({ name: prevPage });
        return;
      }

      switch (this.routeName) {
        case 'fuel-type':
          router.push({ name: this.getReturnRouteName() });
          break;
        case 'heating-system':
          router.push({ name: 'fuel-type' });
          break;
        case 'water-heating-system':
          router.push({ name: this.getBackRouteName() });
          break;
        case 'house-type':
          router.push({ name: this.getBackRouteName() });
          break;
        case 'area-basics':
          router.push({ name: 'house-type' });
          break;
        case 'renovation':
          router.push({ name: 'area-basics' });
          break;
        case 'insulation':
          router.push({ name: 'renovation' });
          break;
        case 'windows-and-doors':
          router.push({ name: 'insulation' });
          break;
        case 'ventilation-system':
          router.push({ name: 'windows-and-doors' });
          break;
        default:
          router.push({ name: 'not-found' });
      }
    },

    next(nextPage = null) {
      if (nextPage) {
        router.push({ name: nextPage });
        return;
      }

      switch (this.routeName) {
        case 'fuel-type':
          router.push({ name: this.getNextRouteName() });
          break;
        case 'heating-system':
          router.push({ name: this.getNextRouteName() });
          break;
        case 'water-heating-system':
          router.push({ name: 'house-type' });
          break;
        case 'house-type':
          router.push({ name: 'area-basics' });
          break;
        case 'area-basics':
          router.push({ name: 'renovation' });
          break;
        case 'renovation':
          router.push({ name: 'insulation' });
          break;
        case 'insulation':
          router.push({ name: 'windows-and-doors' });
          break;
        case 'windows-and-doors':
          router.push({ name: 'ventilation-system' });
          break;
        case 'ventilation-system':
          router.push({ name: 'home-overview' });
          break;
        default:
          router.push({ name: 'not-found' });
      }
    },

    getReturnRouteName() {
      return this.currentBuilding.extra_info.virtual_audit ? 'buildings-list' : 'how-it-works';
    },

    getNextRouteName() {
      if (this.routeName === 'fuel-type') {
        if (this.showHeatingSystem) {
          return 'heating-system';
        }

        if (this.showWaterHeatingSystem) {
          return 'water-heating-system';
        }
      }

      // when current route is 'heating-system'
      return this.showWaterHeatingSystem ? 'water-heating-system' : 'house-type';
    },

    getBackRouteName() {
      if (this.routeName === 'house-type') {
        if (this.showWaterHeatingSystem) {
          return 'water-heating-system';
        }

        if (this.showHeatingSystem) {
          return 'heating-system';
        }

        return 'fuel-type';
      }

      // when current route is 'water-heating-system'
      return this.showHeatingSystem ? 'heating-system' : 'fuel-type';
    },
  },
};
