<template>
  <div class="range-input d-flex mt-4 justify-content-center">
    <validation-provider
      :rules="field.validations"
      :vid="field.name"
      :name="field.name"
      v-slot="{ errors }"
    >
      <div class="p-3 input-box">
        <div class="row">
          <h5 class="range-input-label" :class="rangeDataPresent ? 'col-6 col-md-8' : 'col-12'">
            {{ field.label }}
          </h5>
          <div
            v-if="rangeDataPresent"
            class="col-6 col-md-4 text-right align-self-center text-capitalize"
          >
            <div
              :style="{ backgroundColor: toRgb(getRangeData.color, 0.3) }"
              class="p-1 range-box ml-auto"
            >
              <font-awesome-icon
                class="m-auto"
                :style="{ 'color': getRangeData.color }"
                :icon="['fa', 'circle']"
              />
              <span class="ml-2 text-black-50">{{ getRangeData.value }}</span>
            </div>
          </div>
        </div>

        <b-input-group class="mt-2">
          <b-form-input
            class="d-flex input-field justify-content-center italic col-12"
            :name="field.name"
            v-model="fieldValue"
            :max="field.max"
            :step="field.step"
            inputmode="numeric"
            type="number"
          />
          <b-input-group-append class="range-input-append">
            <span class="m-auto text-black-50 pl-2 pr-2">{{ field.value_text }}</span>
          </b-input-group-append>
        </b-input-group>
        <div class="row">
         <span class="text-left validation-text mt-1 text-left small col-8">
          <i>{{ errors[0] }}</i>
         </span>
          <span
            v-if="tooltip"
            class="col-4 text-right validation-text mt-1 small"
          >
          <i
            :id="`${field.name}-tooltip`"
            :style="tooltip.style"
          >
            {{ tooltip.text }}
          </i>
        </span>
        </div>
      </div>
    </validation-provider>
    <b-popover
      :target="`${field.name}-tooltip`"
      triggers="hover"
      :placement="'right'"
    >
      {{ tooltip.body }}
    </b-popover>
  </div>
</template>

<script>
/* eslint-disable camelcase  */
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },

  props: {
    field: Object,
    sectionTitle: String,
  },

  data() {
    return {
      rangeData: {},
    };
  },

  mounted() {
    if (!this.currentGenericItem.data[this.field.name][0]) {
      this.fieldValue = (this.field.default || this.field.min);
    }
  },

  computed: {
    currentGenericItem() {
      return this.$store.state.generic_items.currentGenericItems[this.sectionTitle];
    },

    tooltip() {
      return this.field.tooltip ? this.field.tooltip : false;
    },

    rangeDataPresent() {
      return !!this.field.range_helper;
    },

    getRangeData() {
      if (!this.rangeDataPresent) return {};

      return this.getRanges(this.field.range_helper, this.fieldValue) || this.rangeData;
    },

    fieldValue: {
      get() {
        return this.currentGenericItem.data[this.field.name][0];
      },
      set(newValue) {
        this.updateRangeValue(newValue);
      },
    },
  },

  methods: {
    updateRangeValue(value) {
      const currentGenericItemData = this.currentGenericItem;
      currentGenericItemData.data[this.field.name] = [value];

      const obj = { key: this.sectionTitle, value: currentGenericItemData };

      this.$store.commit('generic_items/setSectionData', obj);
    },

    getRanges(rangeArray, value) {
      const result = rangeArray.find((item) => {
        const [rangeMin, rangeMax] = item.ranges;

        return value >= rangeMin && value <= rangeMax;
      });

      if (result) this.rangeData = result;

      return result;
    },

    toRgb(hexValue, opacity) {
      if (!hexValue) return false;

      const [r, g, b] = hexValue.match(/\w\w/g).map((value) => parseInt(value, 16));

      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/stylesheets/components/range-input.scss';
</style>
