<template>
  <div
    class="rectangular-border text-center p-2 mt-2"
    :class="homeAnalysis.sub_sections.potential_savings.building_overview_link.border.class"
    :style="homeAnalysis.sub_sections.potential_savings.building_overview_link.border.style"
  >
    <router-link
      :to="{
        name: homeAnalysis.sub_sections.potential_savings.building_overview_link.path_name
      }"
      class="thicker-font-weight"
      :class="homeAnalysis.sub_sections.potential_savings.building_overview_link.class"
      :style="homeAnalysis.sub_sections.potential_savings.building_overview_link.style"
    >
      {{ homeAnalysis.sub_sections.potential_savings.building_overview_link.text }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'BuildingOverviewLink',

  props: {
    homeAnalysis: Object,
  },
};
</script>
