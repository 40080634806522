<template>
  <footer class="footer mt-3">
    <nav class="nav-footer">
      <a :href="partnerLogoLink" target="_blank">
        <img
          class="img-thumbnail partner-logo-footer d-flex ml-auto mr-auto brand"
          :class="partnerLogoAttributes.class"
          :style="partnerLogoAttributes.style"
          :src="partnerLogo"
        />
      </a>
      <span v-for="(option, index) in footer.links" :key="index">
        <span v-if="index > 0"> | </span>
        <a
          :href="option.link"
          :class="option.class"
          :style="option.style"
          target="_blank"
        >
          {{ option.text }}
        </a>
      </span>
    </nav>
    <span class="d-flex justify-content-center text-center pb-4">
      <a
        :href="copyright.link"
        :class="copyright.class"
        target="_blank"
        v-html="copyrightTextFormatted"
      />
    </span>
  </footer>
</template>

<script>

export default {
  data() {
    return {};
  },

  computed: {
    partnerLogo() {
      return this.partner.settings.partner_logo_url;
    },

    partnerLogoLink() {
      return this.partner.settings.partner_logo_link_url;
    },

    partner() {
      return this.$store.state.partners.currentPartner;
    },

    footer() {
      return this.partner.settings.sections.homepage.footer;
    },

    copyright() {
      return this.footer.copyright;
    },

    copyrightTextFormatted() {
      const copyrightSymbolRegex = new RegExp('{{copyright}}', 'g');
      const currentYearRegex = new RegExp('{{currentYear}}', 'g');

      let copyrightText = this.copyright.text;

      copyrightText = copyrightText.replace(copyrightSymbolRegex, '&copy;');
      copyrightText = copyrightText.replace(currentYearRegex, this.currentYear.toString());

      return copyrightText;
    },

    partnerLogoAttributes() {
      return this.footer.logo || {};
    },

    currentYear() {
      return new Date().getFullYear();
    },
  },
};

</script>

<style lang="scss">
@import '../../assets/stylesheets/components/footers/site_footer.scss';
</style>
