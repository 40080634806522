export default {
  methods: {
    documentSectionNotifyClass(currentProject, applied, hasDocumentUploadNotify, listItemId) {
      if (!applied) { return ''; }

      const documentUploadNotifyList = currentProject.document_upload_notify_list || [];

      return (
        hasDocumentUploadNotify
        && documentUploadNotifyList.includes(listItemId)
      ) ? 'border-dashed-red ' : '';
    },

    resetAdminUploadDocumentNotify(currentProjectId) {
      this.$store.dispatch(
        'buildings/updateProjectRequestedEdit',
        {
          id: currentProjectId,
          update: 'admin_document_update_notify',
        },
      );
    },
  },
};
