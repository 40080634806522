import ajax from '../../ajax';

export default {
  methods: {
    async fetchReport(id) {
      return ajax.get(`/api/v2/reports/${id}`, { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'test.pdf');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => Promise.reject(error));
    },
  },
};
