export default {
  methods: {
    objectAttr(obj, attr) {
      if (obj === undefined) return '';

      if (obj[attr] !== undefined) return obj[attr];

      return '';
    },
  },
};
