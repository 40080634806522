<template>
  <b-modal
    :id="componentId"
    :size="'lg'"
    @show="resetModal"
    centered
    class="egx-partner-color custom-modal app"
    hide-footer
    hide-header
  >
    <div class="incentives tag-filter" :style="customCss">
      <h4 class="title">Rebate categories</h4>
      <span class="sub-title">Select 1 or more categories you're interested in.</span>

      <div class="tags-list mt-3">
        <span
          class="mt-4 mr-3"
          v-for="(item, key) in incentiveTags" :key="key"
        >
          <div
            @click="selectIncentiveTag(key)"
            class="btn mb-2 btn-incentive tag-btn"
            :class="incentiveTagsList.includes(key) ? 'btn-egx' : 'btn-egx-white'"
          >
            {{ item }}
          </div>
        </span>
      </div>

      <div class="button mt-3 float-right">
        <div @click="saveIncentiveTags()" class="btn mb-1 btn-egx btn-incentive">
          Filter
        </div>
        <div @click="$bvModal.hide(componentId)" class="btn btn-link">
          Cancel
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'IncentiveTagsFilter',

  props: {
    tagStoreName: {
      String,
      required: true,
    },
    idPrefix: {
      String,
      required: true,
    },
  },

  mounted() {
    this.incentiveTagsList = [...this.selectedIncentiveTags];
  },

  data() {
    return {
      incentiveTagsList: [],
    };
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    incentiveTags() {
      return this.partner.tags.incentive_categories;
    },

    selectedIncentiveTags() {
      return this.$store.state.subsidies[this.tagStoreName];
    },

    componentId() {
      return `${this.idPrefix}-incentive-tags-filter`;
    },

    customCss() {
      return this.$store.state.partners.currentPartner.settings.sections.custom_css;
    },
  },

  methods: {
    tagDispatchName() {
      return this.tagStoreName.charAt(0).toUpperCase() + this.tagStoreName.slice(1);
    },

    resetModal() {
      this.incentiveTagsList = [...this.selectedIncentiveTags];
    },

    selectIncentiveTag(tag) {
      if (this.incentiveTagsList.includes(tag)) {
        this.incentiveTagsList.splice(this.incentiveTagsList.indexOf(tag), 1);
      } else {
        this.incentiveTagsList.push(tag);
      }
    },

    saveIncentiveTags() {
      this.$store.dispatch(`subsidies/set${this.tagDispatchName()}`, this.incentiveTagsList);
      this.$bvModal.hide(this.componentId);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/pages/incentives';
</style>
