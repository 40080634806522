export default {
  methods: {
    emptyObject(object) {
      if (object === undefined || object === null) return true;
      return Object.keys(object).length === 0;
    },

    objectIdNull(object) {
      return object.id === null;
    },
  },
};
