<template>
  <ValidationProvider
    :rules="this.field.validations"
    v-slot="{ errors }"
    class="input-group-lg"
    v-if="toDisplay"
    :name="field.name"
    :vid="field.name"
  >
    <span
      class="ml-2"
      :class="field.label_class"
      v-if="field.show_label"
    >
      {{ field.label }}
    </span>
    <input
      :type="field.type === 'number' ? 'text' : field.type"
      :id="idPrefix + '-' + field.name"
      class="form-control input-field" :class="notValidClass"
      v-model="model[field.name]"
      :name="field.name" :placeholder="field.placeholder"
      :readonly="field.readonly"
      :value="model[field.name]"
      @input="handleInput"
      @change="removeNotValidClass($event.target.value)"
      :disabled="isCopyAddressDisabled || field.disabled"
      :inputmode="field.type === 'number' ? 'numeric' : 'text'"
      v-mask="field.mask"
    />
    <small class="w-100 text-black-50" v-if="field.help_text">{{ field.help_text }}</small>
    <div class="validation-error">
      <span class="w-100 text-black-50">{{ errMessage(errors[0]) }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'InputField',

  inheritAttrs: false,

  components: {
    ValidationProvider,
  },

  props: {
    idPrefix: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    model: {
      type: Object,
      required: true,
    },

    isCopyAddress: Boolean,
  },

  data() {
    return {
      timeout: null,
      notValidClass: '',
      isCopyAddressDisabled: false,
    };
  },

  watch: {
    isCopyAddress() {
      if (this.field.copy_address !== undefined && this.isCopyAddress) {
        if (this.currentUser[this.field.copy_address] !== undefined) {
          this.model[this.field.name] = this.currentUser[this.field.copy_address];
        } else {
          this.model[this.field.name] = this.currentUser.extra_info[this.field.copy_address];
        }
        this.isCopyAddressDisabled = true;
      } else {
        this.isCopyAddressDisabled = false;
      }
    },

    fieldMapping() {
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.users.currentUser;
    },

    toDisplay() {
      if (this.field.parent !== undefined && !this.field.mappings) {
        return this.field.parent_value.includes(this.model[this.field.parent]);
      }

      return true;
    },

    fieldMapping() {
      if (this.field.parent !== undefined && this.field.mappings) {
        const val = this.field.mappings[this.model[this.field.parent]];
        this.$set(this.model, this.field.name, val || '');
      }

      return null;
    },
  },

  methods: {
    errMessage(error) {
      if (error !== undefined) {
        this.notValidClass = 'box-alert';
        return error;
      }

      return undefined;
    },

    removeNotValidClass(value) {
      if (value !== '') {
        this.notValidClass = '';
      }
    },

    handleInput(event) {
      if (this.field.type === 'number') {
        const newValue = event.target.value.replace(/[^0-9.]/g, '');
        this.$emit(this.field.name, newValue);
        this.model[this.field.name] = newValue;
      } else {
        this.$emit(this.field.name, event.target.value);
      }
    },
  },
};

</script>

<style type="scss" scoped>
@import '../../assets/stylesheets/components/form/input_field.scss';
</style>
