export default {
  methods: {
    async timeslotObject(getters, timeslots, timeslotId, attributes) {
      const timeSlotStyles = getters.timeslotSettings.schedule_plan_timeslots;
      const timeslotsWithStartEnd = timeslots.map((timeslot) => {
        let newTimeslot = timeslot;
        if (timeslot.attributes) {
          newTimeslot = timeslot.attributes;
        }
        let timeSlotToColor = newTimeslot;
        if (attributes) {
          if (newTimeslot.id.toString() === attributes.id.toString()) {
            timeSlotToColor = attributes;
          } else {
            timeSlotToColor = newTimeslot;
          }
        }
        const defaultColor = { background: '#ffb6b6', color: '#212121', borderColor: '#FF5722' };
        const colorScheme = {
          background:
            timeSlotStyles.statuses[`${timeSlotToColor.status}`].styles[
              'background-color'
            ] || defaultColor.background,
          color:
            timeSlotStyles.statuses[`${timeSlotToColor.status}`].styles.color || defaultColor.color,
          borderColor: timeSlotStyles.statuses[`${timeSlotToColor.status}`].styles['background-color']
            || defaultColor.borderColor,
        };
        if (newTimeslot.id === timeslotId) {
          colorScheme.borderColor = timeSlotStyles.statuses[`${timeSlotToColor.status}`].styles['border-color']
          || defaultColor.borderColor;
        }
        if (attributes) {
          if (newTimeslot.id.toString() === attributes.id.toString()) {
            return {
              ...newTimeslot,
              ...attributes,
              start: attributes.start_at,
              end: attributes.finish_at,
              backgroundColor: colorScheme.background,
              borderColor: colorScheme.borderColor,
              textColor: colorScheme.color,
              display: 'block',
            };
          }
          return {
            ...newTimeslot,
            start: newTimeslot.start_at,
            end: newTimeslot.finish_at,
            backgroundColor: colorScheme.background,
            borderColor: colorScheme.borderColor,
            textColor: colorScheme.color,
            display: 'block',
          };
        }
        return {
          ...newTimeslot,
          start: newTimeslot.start_at,
          end: newTimeslot.finish_at,
          backgroundColor: colorScheme.background,
          borderColor: colorScheme.borderColor,
          textColor: colorScheme.color,
          display: 'block',
        };
      });
      return timeslotsWithStartEnd;
    },
    async colorSchema({ getters }, timeslot) {
      const timeSlotStyles = getters.timeslotSettings.schedule_plan_timeslots;
      const defaultColor = { background: '#ffb6b6', color: '#212121', borderColor: '#FF5722' };
      const colorScheme = {
        background:
          timeSlotStyles.statuses[`${timeslot.status}`].styles[
            'background-color'
          ] || defaultColor.background,
        color:
          timeSlotStyles.statuses[`${timeslot.status}`].styles.color
            || defaultColor.color,
        borderColor: timeSlotStyles.statuses[`${timeslot.status}`].styles['background-color']
          || defaultColor.background,
      };
      return colorScheme;
    },
  },
};
