<template>
  <span :class="customClasses" :style="customStyles" @click="triggerFunction">
    {{ field.text }}
  </span>
</template>

<script>
export default {
  name: 'RouterSpanField',

  props: {
    field: {
      type: Object,
      required: true,
    },

    triggerFunction: {
      type: Function,
      required: true,
    },

    customClasses: Array,
    customStyles: Object,
  },
};
</script>
