<template>
  <div class="container">
    <form id="admin-user-sign-up" class="admin-user-sign-up m-auto">
      <div class="mt-5">
        <h1
          class="text-center"
          :class="adminUserSignUpFields.attributes.title.class"
          :style="adminUserSignUpFields.attributes.title.style"
        >
          {{ adminUserSignUpFields.attributes.title.text }}
        </h1>
        <h4
          class="text-center"
          :class="adminUserSignUpFields.attributes.subtitle.class"
          :style="adminUserSignUpFields.attributes.subtitle.style"
        >
          {{ adminUserSignUpFields.attributes.subtitle.text }}
        </h4>
        <div class="input-group-lg mt-5">
          <ValidationObserver
            tag="form"
            @submit.prevent="signUp()"
            ref="adminUserSignUp"
          >
            <form-field
              :formFields="adminUserSignUpFields.fields"
              :show="true"
              :model="data.registerUser"
              :id-prefix="idPrefix"
            />
            <div class="form-check col-12" v-show="adminUserSignUpFields.consent.show">
              <input
                class="form-check-input" type="checkbox" value=""
                v-model="data.registerUser.consent"
                id="user-consent"
              >
              <label
                class="form-check-label"
                for="user-consent"
                :class="adminUserSignUpFields.consent.class"
                :style="adminUserSignUpFields.consent.style"
              >
                <external-link-field :field="termsAndConditions" />
              </label>
            </div>
            <div class="form-check col-12" v-show="adminUserSignUpFields.marketing.show">
              <input
                class="form-check-input" type="checkbox" value=""
                :class="adminUserSignUpFields.marketing.class"
                :style="adminUserSignUpFields.marketing.style"
                v-model="data.registerUser.subscribed"
                id="user-subscribed"
              >
              <label class="form-check-label" for="user-subscribed">
                {{ adminUserSignUpFields.marketing.text }}
              </label>
            </div>

            <button
              type="submit"
              class="btn btn-lg d-flex ml-auto mr-auto mt-5"
              :class="adminUserSignUpFields.attributes.button.class"
              :style="adminUserSignUpFields.attributes.button.style"
            >
              {{ adminUserSignUpFields.attributes.button.text }}
            </button>
          </ValidationObserver>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ExternalLinkField from '@/components/form/ExternalLinkField.vue';
import FormField from '../../components/form/FormField.vue';
// eslint-disable-next-line import/no-cycle
import router from '../../router/index';

export default {
  components: {
    FormField,
    ExternalLinkField,
  },

  data() {
    return {
      idPrefix: 'admin-user-sign-up',

      data: {
        registerUser: {
          who_dunnit_admin_id: '',
          name: 'New user',
          subscribed: false,
          consent: false,
        },
      },
    };
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    partnerSettings() {
      return this.partner.settings;
    },

    termsAndConditions() {
      return {
        path: this.partnerSettings.terms_and_condition_link,
        text: this.adminUserSignUpFields.consent.text,
        openNewPage: true,
      };
    },

    adminUserSignUpFields() {
      return this.partnerSettings.sections.admin_user_sign_up;
    },

    adminId() {
      return this.$route.params.admin_id;
    },
  },

  methods: {
    signUp() {
      if (!this.data.registerUser.consent) {
        this.$notify({
          group: 'notice',
          text: 'The terms and conditions field is required.',
        });
      } else {
        this.$refs.adminUserSignUp.validate()
          .then((isValid) => {
            if (isValid) {
              this.data.registerUser.who_dunnit_admin_id = this.adminId;

              this.$store.dispatch('users/adminUserSignUp', this.data.registerUser)
                .then(() => router.push({ name: 'buildings-list' }))
                .catch((error) => {
                  this.$notify({
                    group: 'notice',
                    text: error.response.data.errors[0].title,
                  });
                });
            } else {
              this.$notify({
                group: 'notice',
                text: 'Fill in all required fields.',
              });
            }
          });
      }
    },

    setAdminId() {
      this.$store.dispatch('admins/setAdminId', this.adminId);
    },
  },

  mounted() {
    this.$store.dispatch('auth/signOut');
    this.setAdminId();
    this.data.registerUser.consent = this.adminUserSignUpFields?.consent?.checked;
    this.data.registerUser.subscribed = this.adminUserSignUpFields?.marketing?.checked;
  },
};
</script>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/components/admin/admin_user_sign_up';
</style>
